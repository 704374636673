/* ensure all pages have Bootstrap CSS */
@import "~bootstrap/dist/css/bootstrap.min.css";
@import './fonts.css';
/* Fonts */
@font-face {
  font-family: MontserratRegular;
  src: url("../fonts/Montserrat-Regular.woff") format("woff");
}
@font-face {
  font-family: MontserratBlack;
  src: url("../fonts/Montserrat-Black.woff") format("woff");
}
@font-face {
  font-family: MontserratBold;
  src: url("../fonts/Montserrat-Bold.woff") format("woff");
}
@font-face {
  font-family: MontserratExtraBold;
  src: url("../fonts/Montserrat-ExtraBold.woff") format("woff");
}
@font-face {
  font-family: RobotoBold;
  src: url("../fonts/Roboto-Bold.woff") format("woff");
}
@font-face {
  font-family: RobotoMedium;
  src: url("../fonts/Roboto-Medium.woff") format("woff");
}

@font-face {
  font-family: PoppinsRegular;
  src: url("../fonts/Poppins-Regular.woff") format("woff");
}
@font-face {
  font-family: PoppinsLight;
  src: url("../fonts/Poppins-Light.woff") format("woff");
}
@font-face {
  font-family: PoppinsBold;
  src: url("../fonts/Poppins-Bold.woff") format("woff");
}

html,
body {
  margin: 0px;
}
html,
body,
p,
button,
input,
select,
textarea,
pre,
code,
kbd,
tt,
var,
samp,
.main-navigation a,
search-input-holder .search-field,
.widget .widgettitle,
.widget .widget-title {
  font-family: 'Open SansVariable';
}
.main-navigation.sec-main-navigation ul ul a {
  text-align: left;
}

p,
ul li,
ol li {
  font-size: 18px;
}
ul,
ol {
  padding-left: 20px;
}

/* Header Styles */
.header {
  vertical-align: top;
  background-color: #fff;
  margin: 0 auto;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.header .phone::before {
  content: url("../public/footer-phone.svg");
  position: relative;
  vertical-align: middle;
  margin-right: 10px;
}
.logo {
  display: inline-block;
  vertical-align: middle;
}
.logo img {
  width: 357px;
}
.topnav {
  display: inline-block;
  margin-left: 2%;
}
.topnav a,
.bottom-nav a,
.navbar-light .navbar-nav .nav-link {
  font-family: MontserratBold;
  font-size: 16px;
  line-height: 19px;
  color: #03589c !important;
  text-decoration: none;
  margin-right: 20px;
  vertical-align: sub;
}
.topnav a.active {
  margin-top: -5px;
  border-bottom: 2px solid rgba(3, 88, 156, 0.5);
  padding: 5px 4px;
  background: linear-gradient(to bottom, rgba(3, 88, 156, 0.1) 0%, rgba(3, 88, 156, 0.1) 100%) no-repeat 0px 15px;
  background-clip: content-box;
}
.topnav a.secondary::after,
a.secondary.mobile::after {
  /*content: url("../public/arrow-down.png");*/

  background-image: url("../public/arrow-down.png");
  background-size: contain;
  display: inline-block;
  width: 10px;
  height: 6px;
  background-repeat: no-repeat;
  position: relative;
  top: -1px;
  margin-left: 5px;
  content: "";
}
a.secondary.mobile::after {
  width: 18px;
  height: 13px;
}
.navbar { padding: 0rem 1rem;}
.dropdown-item {
  padding: 0.75rem 1.5rem;
}
.dropdown-toggle::after {
  content: url("../public/arrow-down.png");
  border-top: none;
}
.dropdown { margin-top: -12px;}
.dropdown a { margin-right: 10px !important; }
.my-account-btn, .my-account-btn a {
  display: inline-block;
  color: #a5aaaf;
  font-size: 16px;
  text-decoration: none;
  font-family: MontserratBold;
}
.my-account-btn::before {
  content: url("../public/my-acc-icon.svg");
  position: relative;
  top: 3px;
  right: 4px;
}
.free-search-btn {
  display: inline-block;
  background: #f37217;
  padding: 14px 19px;
  color: #fafaff;
  font-family: MontserratBold;
  font-size: 14px;
  line-height: 24px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  margin-left: 15px;
}
.free-search-btn a {
  color: #fafaff;
  text-decoration: none;
}
.top-header-phone {
  display: inline-block;
  padding: 12px 19px;
  color: #7B848D;
  font-family: MontserratBold;
  font-size: 14px;
  line-height: 24px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  border: 2px solid #a5aaaf;
  margin-left: 32px;
}
.top-header-phone a {
  color: #7B848D;
  text-decoration: none;
}
.mobile-menu {
  display: none;
}

/* Footer Styles */
.footer {
  padding-top: 15px;
  padding-bottom: 20px;
  background-color: #a5aaaf;
  text-align: center;
}
.footer-wrapper {
  max-width: 1180px;
  margin: 0 auto;
}
.footer-links {
  border-bottom: 1px solid rgba(236, 237, 241, 0.3);
  padding: 32px 20px;
}
.footer .payments {
  margin-top: 35px;
}
.footer .copyright {
  font-family: MontserratRegular;
  font-size: 11px;
  line-height: 14px;
  color: #ecedf1;
  margin: 0 auto;
  margin-top: 24px;
  max-width: 930px;
}
.footer h5 {
  font-family: MontserratBold;
  color: #ffffff;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 18px;
}
.footer-links a:link,
.footer-links a:active,
.footer-links a:visited {
  font-family: MontserratRegular;
  font-size: 12px;
  line-height: 15px;
  color: rgba(236, 237, 241, 0.7);
  text-decoration: none;
  display: block;
  margin-bottom: 14px;
}
.footer .link-wrapper {
  width: 24%;
  display: inline-block;
  vertical-align: top;
  text-align: left;
}

.footer h5.social {
  margin-bottom: 10px;
  margin-top: 50px;
}
.footer .links.social a {
  display: inline-block;
  margin-right: 10px;
}
.footer .links.social a:nth-child(0) {
  margin-right: 0px;
}

.footer .union {
  cursor: pointer;
  background: url(../public/Union.png) no-repeat;
  width: 140px;
  height: 63px;
  padding-top: 13px;
  padding-left: 12px;
}
.footer .union a {
  display: inline-block;
  margin-right: 6px;
  vertical-align: middle;
}
.footer .union a:nth-child(0) {
  margin-right: 0px;
}

.footer .phone {
  margin-top: 37px;
}
.footer .phone::before {
  content: url("../public/footer-phone.svg");
  position: relative;
  vertical-align: middle;
  margin-right: 10px;
}
.footer .phone a {
  font-family: MontserratRegular;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 0px;
  display: inline-block;
}
.footer .phone a,
.footer .phone p {
  color: #fafaff;
}
.footer .phone p {
  font-family: MontserratBold;
  font-size: 10px;
  line-height: 12px;
  margin-top: 2px;
  margin-left: 33px;
}

/* Review box*/
.review-box { max-width: 310px; width: 100%; background-color: #fff; padding: 24px; display: inline-block; text-align: left;     margin-bottom: 20px; }
.review-box .review-icon { width: 40px; height: 40px;
  display: inline-block; vertical-align: middle;}
.review-box .review-icon img {  margin: 0px !important; width: 100%; height: 100%; -webkit-border-radius: 25px; -moz-border-radius: 25px; border-radius: 25px;
  background-color: gray;}
.review-box .star-box { width: 20px; height: 20px; background: url("../public/rate-star.png") no-repeat center;
  display: inline-block;  vertical-align: top; margin-right: 2px;}
.review-box .star-box:nth-last-child(1) { margin-right: 0px;}
.review-box .stars-wrapper { display: inline-block; margin-left: 10px; height: 20px; vertical-align: middle;}
.review-box .star-box.rate5 { background-color: #50B27F;}
.review-box .star-box.rate4 { background-color: #89CB41;}
.review-box .star-box.rate3 { background-color: #F8CE46;}
.review-box .star-box.rate2 { background-color: #F08B3E;}
.review-box .star-box.rate1 { background-color: #EC4A34;}
.review-box .star-box.rate0 { background-color: #DCDCE5;}
.review-box .who-wrapper { display: block; margin-top: 5px; margin-bottom: 15px; text-align: center;}
.review-box .review-first-row {text-align: center;}
.review-box .who-wrapper span.review-text { font-family: 'RobotoMedium';  font-size: 12px; line-height: 14px; color: #ADADAD; margin-left: 4px; margin-right: 4px;}
.review-box .who-wrapper span { font-family: 'RobotoBold';  font-size: 12px; line-height: 14px;  color: #1B1B21;}
.review-box p { font-family: 'RobotoMedium';  font-size: 14px; line-height: 19px; color: #1B1B21; margin: 0px;text-align: center; }

@media (min-width: 769px) and (max-width: 1450px) {
  /* iPad Pro*/
  .topnav {
    margin-left: 0px;
  }
  .dropdown {
    margin-top: -10px;
  }
  .topnav a, .navbar-light .navbar-nav .nav-link,
  .bottom-nav a {
    font-size: 10px;
  }
  .logo img {
    width: 220px;
  }
  .my-account-btn, .my-account-btn a {
    font-size: 10px;
  }
  .free-search-btn {
    font-size: 10px;
    padding: 9px 19px;
  }
  .top-header-phone {
    padding: 6px 19px;
  }
  .top-header-phone a {
    font-size: 10px;
  }
}
@media (min-width: 640px) and (max-width: 768px) {
  /* iPad */
}
@media (max-width: 360px) {
  .logo img {
    width: 90%;
  }
}
@media (max-width: 800px) {
  .logo img {
    width: 90%;
  }
  .review-box { max-width: 100%;}

  .header {
    padding-left: 10px;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .footer .link-wrapper {
    width: 48%;
  }
  .logo {
    display: inline-block;
    width: 80%;
  }
 
  .topnav,
  .my-account-btn,
  .free-search-btn,
  .top-header-phone {
    display: none;
  }
  .mobile-menu .my-account-btn,
  .mobile-menu .free-search-btn {
    display: block !important;
  }
  .mobile-menu {
    display: inline-block;
    width: 20%;
  }

  .MuiModal-root.MuiDrawer-root.MuiDrawer-modal {
    top: 89px;
  }
  .MuiDrawer-modal .MuiDrawer-paper {
    width: 100%;
    margin-top: 66px;
    padding: 45px !important;
    background-color: #fff !important;
  }
  .MuiPaper-root.MuiPaper-elevation a:link,
  .MuiPaper-root.MuiPaper-elevation a:visited {
    font-family: MontserratRegular;
    font-style: normal;
    font-weight: normal;
    font-size: 1em;
    line-height: 32px;
    color: #03589c;
    text-decoration: none;
    margin-right: 35px;
  }
  .secondary-miu-menu .dropdown-item {
    line-height: 20px !important;    
  }
  .secondary-miu-menu .dropdown-item a:link,
  .secondary-miu-menu .dropdown-item a:visited {
    font-size: 17px !important;
    line-height: 20px !important;    
    margin-right: 0px;
  }

  .MuiPaper-root.MuiPaper-elevation .my-account-btn,
  .MuiPaper-root.MuiPaper-elevation .our-guarantee {
    display: inline-block;
    color: #7b848d !important;
    font-size: 18px !important;
    line-height: 22px !important;
    font-family: MontserratRegular;
  }
  .MuiPaper-root.MuiPaper-elevation .my-account-btn::before {
    content: url("../public/my-acc-icon-mobile.svg");
    position: relative;
    top: 3px;
    right: 4px;
    margin-right: 5px;
  }
  .MuiPaper-root.MuiPaper-elevation .our-guarantee {
    margin-bottom: 15px;
  }
  .MuiPaper-root.MuiPaper-elevation .our-guarantee::before {
    content: url("../public/our-guarantee-icon.svg");
    position: relative;
    top: 3px;
    right: 4px;
    margin-right: 7px;
    margin-left: 1px;
  }

  .nav-link { padding: 0; }
}

input {
  font-family: 'Open SansVariable';
  font-size: 18px;
  line-height: 24px;
  color: #a5aaaf;
  padding: 13px 19px;
  background: #ffffff;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 4px;
}

input[type="email"].is-invalid~.invalid-feedback {
  display: block !important;
}

input[type="email"].is-invalid{
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
  border-color: #dc3545 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") !important;
}

input[type="email"].is-valid{
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
  border-color: #28a745 !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") !important;
}


select {
  appearance: unset;
  background-repeat: no-repeat !important;
  background-origin: content-box !important;
  background-position-x: right !important;
  background-position-y: center !important;
  background-image: url("../public/dropdown-arrow.svg") !important;
}

.form-group-email {
  position: relative;
}

.form-control {
  height: 48px;
}

.form-control-loader {
  position: absolute;
  bottom: calc((48px - 2rem) / 2 + 24px);
  right: 10px;
}

.was-validated select.form-control:valid,
.was-validated select.form-control:invalid {
  padding-right: calc(0.375em + 0.1875rem) !important;
  background-size: calc(0.75rem + 0.375rem) calc(0.75rem + 0.375rem) !important;
}

.was-validated select.form-control:valid {
  background-image: url("../public/dropdown-arrow-success.svg") !important;
}

.was-validated select.form-control:invalid {
  background-image: url("../public/dropdown-arrow-failure.svg") !important;
}

select.form-control.is-valid,
select.form-control.is-invalid {
  padding-right: calc(0.375em + 0.1875rem) !important;
  background-size: calc(0.75rem + 0.375rem) calc(0.75rem + 0.375rem) !important;
}

select.form-control.is-valid {
  background-image: url("../public/dropdown-arrow-success.svg") !important;
}

select.form-control.is-invalid {
  background-image: url("../public/dropdown-arrow-failure.svg") !important;
}


.form-check .form-check-input:checked {
  position: absolute;
  left: -9999px;
}

.form-check .form-check-input:not(:checked) {
  position: absolute;
  left: -9999px;
}

.form-check .form-check-input:checked + label,
.form-check .form-check-input:not(:checked) + label {
  display: inline-block;
  position: relative;
  padding-left: 32px;
  font-family: 'Open SansVariable';
  font-size: 16px;
  line-height: 24px !important;
  vertical-align: middle;
  cursor: pointer;
  font-weight: 100;
}

.form-check .form-check-input:checked + label {
  color: #03589C;
  font-family: 'Open SansVariable';
  font-weight: 600;
}

.form-check .form-check-input:not(:checked) + label {
color: #394149;
}

.form-check .form-check-input:checked + label:before {
content: '';
display: inline-block;
position: absolute;
left: 0px;
top: 1px;
width: 22px;
height: 22px;
padding: 7px;
background: url("../public/form-check-checked.svg") no-repeat center;
}

.form-check .form-check-input:not(:checked) + label:before {
content: '';
position: absolute;
left: 0;
top: 1px;
width: 22px;
height: 22px;
background: url("../public/form-check-unchecked.svg") no-repeat center;
}

.form-control:focus {
-webkit-box-shadow: 0 0 0 5px #F37217 !important;
box-shadow: 0 0 0rem 5px #F37217 !important;
outline-color: #ffffff;
outline-style: auto;
outline-width: 1px;
outline-offset: 2px;
}

label[required] {
position: relative;
}

label[required]:after {
content: "*";
display: inline-block;
position: absolute;
font-family: MontserratRegular;
font-size: 14px;
color: #F37217;
}

.processing-feedback {
width: 100%;
margin-top: 0.25rem;
font-size: 80%;
color: #495057;
}

.orange-btn {
background: #f37217;
padding: 15px 19px;
color: #ffffff;
font-family: MontserratBold !important;
font-size: 14px !important;
line-height: 24px;
text-decoration: none;
border-radius: 4px;
width: 100%;
display: inline-block;
}

h2 {
font-family: MontserratBold;
font-style: normal;
font-size: 36px;
line-height: 44px;
color: #03589c;
margin-bottom: 40px;
margin-top: revert;
}
h3 {
font-family: MontserratBold;
font-style: normal;
font-size: 16px;
line-height: 20px;
color: #7b848d;
margin-top: 5px;
}
h4 {
font-family: MontserratRegular;
font-style: normal;
font-size: 18px;
line-height: 22px;
color: #f37217;
font-weight: 400;
}
p {
font-family: MontserratRegular;
font-style: normal;
font-size: 14px;
line-height: 24px;
}

.MuiModal-root .MuiPaper-root.MuiPaper-elevation {
background: #03589c;
box-shadow: 5px 0 5px rgb(0 0 0 / 20%);
-webkit-box-shadow: 5px 0 5px rgb(0 0 0 / 20%);
-moz-box-shadow: 5px 0 5px rgba(0, 0, 0, 0.2);
-ms-box-shadow: 5px 0 5px rgba(0, 0, 0, 0.2);
-o-box-shadow: 5px 0 5px rgba(0, 0, 0, 0.2);
border-radius: 5px;
padding: 0px;
}
.MuiPaper-root.MuiPaper-elevation a {
font-size: 15px;
line-height: 38px;
text-decoration: none;
color: #fff;
font-weight: bold;
}

@media (max-width: 640px) {
.home .top-banner input {
font-size: 1em;
}
}
