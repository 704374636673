
/* home page */
.home { position: relative; }
.home .top-banner { background: url("../public/home-banner.jpg") no-repeat; background-size : cover; min-height: 800px; background-position: 50% 50% }
.home .top-banner .copy { text-align: center; padding-top: 9.5%; margin-left: auto; margin-right: auto;}
.top-banner .bottom {font-family: MontserratRegular; font-weight: 400; margin-top: 113px; font-size: 28px; line-height: 59px; color: #fff;}
.home .top-banner h1 { font-family: MontserratBold; font-style: normal;font-weight: 700; font-size: 48px; line-height: 59px; color: #fff;
	margin-bottom: revert; }

.home .top-banner input { max-width:450px; width: 100%; margin-right: 10px;  }
.home .top-banner .orange-btn { max-width: 150px; }

.recently-trademarks { background: #FAFAFF; padding-top: 40px; padding-bottom: 40px;text-align: center; }
.recently-trademarks h2 { color: #7B848D; }
.trademark { background: #FFFFFF; box-shadow: 0px 7px 15px rgba(168, 168, 168, 0.12); border-radius: 85px;
	display: inline-block; vertical-align: middle; width: 170px; height: 170px; position: relative; margin-right: 32px;}
.trademark:last-child { margin-right: 0px; }
.trademark img { position: absolute; top: 34%; left: 15%; width: 125px;}
.trademark.t1 img { top: 39%; left: 15%; }
.trademark.t2 img { top: 26%; left: 14%; }
.trademark.t3 img { top: 35%; left: 15%; }
.trademark.t4 img { top: 30%; left: 15%; }
.trademark.t5 img { top: 34%; left: 17%; }
.trademark.t6 img { top: 30%; left: 17%; }
.trademark.t7 img { top: 34%; left: 16%; }

.recently-trademarks .react-multi-carousel-list { margin: 0 auto; max-width: 1280px; }
.recently-trademarks .react-multiple-carousel__arrow { display: none;}
.recently-trademarks .react-multi-carousel-dot-list {  position: inherit; margin-top: 20px; }

.step-process { text-align: center; padding-top: 70px; padding-bottom: 70px;}
.step-process h2 {   margin-bottom: 10px; }
.step-process h4 { margin-bottom: 33px;}
.step-process p { color: #7B848D; margin-top: 25px; }
.step-process .steps-wrapper { margin-top: 30px; }
.step-process .step-box { text-align: center; display: inline-block; max-width: 304px; vertical-align: top; }
.step-process .next { content:url('../public/arrow-right.svg');  display:inline-block; margin-top: 9%; margin-left: 30px; margin-right: 30px; }

.testimonials { background-color: #ECEDF1; padding-top: 50px; }
.content-wrapper { margin: 0 auto; max-width: 1680px; width: 100%; }
.testimonials .content-wrapper { background: url("../public/testimonials-bg.png") no-repeat left bottom; text-align: center; padding-bottom: 15%; }
.testimonials .copy { max-width: 410px; display: inline-block; vertical-align: top; text-align: left; margin-right: 5%; margin-top: 4%;}
.testimonials .t-boxs-wrapper { display: inline-block; margin-right: -80px;}
.testimonials .t-boxs-wrapper .t-col { display: inline-block; vertical-align: top;  max-width: 328px; }
.testimonials .t-boxs-wrapper .t-col img { margin-bottom: -30px; margin-left: -10px;}

@media (max-width: 640px) { 
	.testimonials .t-boxs-wrapper .t-col img { width: 90%;}
	
}

.protect-banner {  background: url("../public/monay-bg.jpg") no-repeat center / contain; height: 297px; text-align: center;
	background-size: cover;  }
.protect-banner h2 { color: #FFFFFF; margin-bottom: 10px;  }
.protect-banner p { font-size: 24px; line-height: 32px;  max-width:570px; margin-left: auto; margin-right: auto; color: #FFFFFF; margin-top: 5px;}
.protect-banner .copy { display: inline-block; margin-top: 3%; }
.protect-banner img {     display: inline-block; vertical-align: bottom; }

.step-process.protect .step-box { margin-right: 100px; }
.step-process.protect .step-box:last-child { margin-right: 0px; }

/* todo move faq to seperate .module.css*/
.faq { background: #FAFAFF; padding-top: 60px; padding-bottom: 70px; text-align: center;}
.faq .left-side { width: 31%; display: inline-block; vertical-align: top; text-align: left;}
.faq .left-side h2 { color: #7B848D; margin-bottom: 10px; }
.faq .left-side p { color: #7B848D; font-family: MontserratBold; font-size: 16px; line-height: 20px; }
.faq .left-side p a:link, .faq .left-side p a:visited { color: #03589C; }
.faq .right-side { width: 46%; display: inline-block; margin-left: 7%; text-align: left; }

.faq.no-left-side {background: #FFF;}
.faq.no-left-side .right-side { margin-left: 0px; width: auto;  }
.faq.no-left-side h2.accordion-header  { width: 707px }

@media (max-width: 640px) { 
	.faq { padding-left: 20px; padding-right: 20px;}
	.faq.no-left-side .right-side { width: 100%;}
	.faq-page .sides-wrapper .left-side { width: 100% !important; margin-right: 0px !important; }
}

.accordion-button {  background-color: transparent !important; color: #7B848D !important; font-family: MontserratBold; padding: 0.5rem 1rem; }
.accordion-button:not(.collapsed) { background-color: transparent !important; color: #03589C !important; box-shadow: initial !important;}
.accordion-button:not(.collapsed)::after { background-image: url("../public/minus.svg") !important; 
		filter: invert(40%) sepia(85%) saturate(583%) hue-rotate(345deg) brightness(111%) contrast(102%); }
.accordion-button::after { background-image: url("../public/plus.svg") !important; }
.accordion-item { background-color: transparent !important; border: 0px !important; }
h2.accordion-header  { color: #7B848D !important; margin-top: 0px !important; }
.accordion-body { font-family: MontserratRegular; color: #7B848D !important; font-size: 14px !important; line-height: 24px; }
/*******/
.dont-wait-container { margin-top: 100px; margin-bottom: 100px; }
.dont-wait-banner { background: url("../public/dont-wait-bg.jpg") no-repeat center / contain; height: 300px; text-align: center;}
.dont-wait-banner .copy { padding-top: 3%; }
.dont-wait-banner h2 { color: #fff; margin-bottom: 25px !important; }
.dont-wait-banner .orange-btn { width: 200px; }
.dont-wait-bottom-text { margin-top: 10px; margin-left: auto; margin-right: auto; margin-bottom:0;max-width: 1400px; text-align: center; color: #7B848D; font-family: 'Open SansVariable'; font-size: 14px; line-height: 24px;}

/* about page */
.about { position: relative; }
.about .top-banner { background: url("../public/about-banner.jpg") no-repeat; background-size : cover; min-height: 450px; }	
.about .top-banner .copy { text-align: center; padding-top: 10%; }
.about .top-banner h1 { font-family: MontserratBold; font-style: normal;font-weight: 700; font-size: 48px; line-height: 59px; color: #fff;
	margin-bottom: revert; }

.about .main-copy { max-width: 1280px; margin: 0 auto;  margin-top: 75px;}
.about .main-copy .text { text-align: left; display: inline-block; width: 66%; vertical-align: middle;}
.about .main-copy img { max-width: 29%; position: relative; top: 42px;}
.about h2 { margin-bottom: 10px; }
.about h3 { color: #7B848D; font-family: MontserratRegular; font-size: 18px; line-height: 22px; margin-bottom: 65px; }
.about p {  color: #7B848D; font-family: MontserratRegular; font-size: 14px; line-height: 24px; }
.about p span { color:#03589C; font-family: MontserratBold; }
.about .divider { width: 1px; height: 384px; background: #ECEDF1; display: inline-block; vertical-align: top; margin-left: 40px; margin-right: 50px;}
.about .left-s, .about .right-s { max-width: 320px; display: inline-block; vertical-align: top;}

.secondary-menu { max-width: 1190px; margin: 0 auto; border-bottom: 1px solid #ECEDF1; margin-top: 32px;}
.secondary-menu .link-wrapper { display: inline-block;  max-width: 33%; width: 100%; height: 60px; text-align: center; padding-top: 17px; }
.secondary-menu a:link, .secondary-menu a:visited {  color: #7B848D; font-family: MontserratRegular; font-size: 16px; line-height: 24px;  
	  text-decoration: none; padding: 22px 10px;}
.secondary-menu a.active { color: #03589C; font-family: MontserratBold; border-bottom: 2px solid #F37217; }

@media (max-width: 640px) { 
	.about .top-banner { background: url("../public/about-banner-m.jpg") no-repeat; background-size : cover; min-height: 250px; }
	.about .top-banner h1 { font-size: 42px; }
	.about .top-banner .copy { padding-top: 26%; }

	.secondary-menu { margin-top: 0px; }
	.secondary-menu .link-wrapper { width: 33%;  }
	.secondary-menu a:link, .secondary-menu a:visited { font-size: 12px; }

	.about .main-copy { margin: 0 20px; }
	.about h2 { font-size: 28px; line-height: 34px; width: 59%;	}
	.about h3 { font-size: 16px; line-height: 19px; width: 59%; }
	.about .main-copy .text { position: relative; width: auto; overflow: hidden;}
	.about .main-copy img { max-width: 68%;	position: absolute;	top: 18px; right: -23%;	}		
		
}

/* search results page */
.home.search .top-banner { min-height: 650px; }
.home.search .top-banner .copy {padding-top: 3%;}
@media (max-width: 1920px) {
	.home.search .top-banner .copy {padding-top: 5%;}
}
@media (max-width: 640px) { 
	.home.search .top-banner { min-height: 385px;}
}

.quick-easy-process { background: #FAFAFF; padding: 40px 0; border-bottom: 2px solid #ECEDF1; }
.quick-easy-process .wrapper { max-width: 960px; margin: 0 auto; }
.quick-easy-process h2 { color: #7B848D; font-family: MontserratRegular; font-size: 24px; line-height: 32px; display: inline-block; 
	margin-right: 40px; margin-bottom: 0px;}
.quick-easy-process .shield-box { background: url('../public/shield.png'); background-size: contain; width: 57px;height: 64px; 
	display: inline-block; vertical-align: middle; font-family: MontserratBold; font-size: 24px; line-height: 32px; color: #FFFFFF; 
	text-align: center; padding-top: 16px; margin-right: 24px; }
.quick-easy-process h3 { color: #7B848D; font-family: MontserratBold;  font-size: 24px; line-height: 32px; display: inline-block;
	vertical-align: middle;}
.quick-easy-process h3::after {content: url('../public/arrow-right-orange.svg');  margin: 0 40px;}
.quick-easy-process h3:nth-last-child(1)::after { content: ''; margin: 0px;}

@media (max-width: 640px) { 
	.quick-easy-process { text-align: center; }
	.quick-easy-process h2 { margin-right: 0px; margin-bottom: 25px; display: block;}
	.quick-easy-process h3::after { margin: 0px; content: none;}
	.quick-easy-process .shield-box, .quick-easy-process h3 { display: block;}
	.quick-easy-process .shield-box { margin: 0 auto; }
	.quick-easy-process .shield-box-wrapper { text-align: center; display: inline-block; width: 30%;} 	
	.quick-easy-process .arrow { background: url('../public/arrow-right-orange.svg') no-repeat;  margin: 0; width: 9px; height: 24px; display: inline-block;
		vertical-align: top;  margin-top: 11%;}
}
.table-results { background-color: #fff; padding: 70px 0;position: relative;}
.table-results .wrapper { max-width: 1180px; margin: 0 auto;}
.table-results h5 { display: inline-block; color: #394149; font-family: MontserratBold; font-size: 12px; line-height: 15px;
	text-transform: uppercase;  }
.table-results h5 span { color: #F37217; }
.table-results .sort-by .MuiBox-root { width: 242px; display: inline-flex; margin-left: 18px; }
.table-results table { margin-top: 36px;}
.table-results table .header { border-top: 2px solid #ECEDF1; border-bottom: 2px solid #ECEDF1; padding: 15px 0px; 
	height: 56px; vertical-align: middle;}
.table-results table th { color: #7B848D; font-family: MontserratBold;  font-size: 12px; line-height: 15px; 
	text-transform: uppercase; } 
.table-results table tr { border-bottom: 1px solid #ECEDF1;  }
.table-results table th:nth-last-child(1), .table-results table th:nth-last-child(2), .table-results table th:nth-last-child(3) { width: 6%; }
.table-results table th:nth-last-child(4), .table-results table th:nth-last-child(5), .table-results table th:nth-last-child(6) { width: 15%; }
.table-results table th:nth-last-child(7), .table-results table th:nth-last-child(8) { width: auto; }
.table-results table td { text-align: center; padding: 20px 10px;}
.table-results .title { color: #03589C; font-family: MontserratBold;  font-size: 14px; line-height: 24px; text-align: center; cursor: pointer; }
.table-results .owner { color: #394149; font-family: MontserratRegular;  font-size: 14px; line-height: 24px; text-align: center; }
.table-results .docs { background: url('../public/docs-icon.svg') no-repeat center center;}
.table-results .status.live { background: url('../public/Live.png') no-repeat center center; }
.table-results .status.dead { background: url('../public/Dead.png') no-repeat center center;}
.table-results tr:nth-child(odd) {background: #FAFAFF;}
.table-results .dead-row {background: rgba(248, 227, 227, 0.3) !important; }
@media (min-width: 641px) and (max-width: 1279px)
{
	.table-results .wrapper { max-width: 100%; margin: 0;}
	.table-results .sides .side-box { display: none}
}
@media (min-width: 1280px) and (max-width: 1380px)
{
	.table-results .wrapper { max-width: 800px; margin: 0;}
	.table-results .sides .side-box { width: 400px; padding: 20px; padding-top: 45px;}
	.table-results .sides .side-box ul li span {margin-right: 10px;}
	.table-results .sides .side-box .orange-box { padding: 10px;}
}
@media (min-width: 1380px) and (max-width: 1480px)
{
	.table-results .wrapper { max-width: 900px; margin: 0;}
	.table-results .sides .side-box { width: 400px; padding: 20px; padding-top: 45px;}
	.table-results .sides .side-box ul li span {margin-right: 10px;}
	.table-results .sides .side-box .orange-box { padding: 10px;}
}
@media (min-width: 1480px) and (max-width: 1580px)
{
	.table-results .wrapper { max-width: 1000px; margin: 0;}
	.table-results .sides .side-box { width: 400px; padding: 20px; padding-top: 45px;}
	.table-results .sides .side-box ul li span {margin-right: 10px;}
	.table-results .sides .side-box .orange-box { padding: 10px;}
}
@media (min-width: 1580px) and (max-width: 1680px)
{
	.table-results .wrapper { max-width: 1000px; margin: 0;}
}
@media (min-width: 1680px) and (max-width: 1780px)
{
	.table-results .wrapper { max-width: 1100px; margin: 0;}
}
@media (min-width: 1780px) and (max-width: 1920px)
{
	.table-results .wrapper { max-width: 1180px; margin: 0; margin-left: 3%;}
}
@media (min-width: 1920px) and (max-width: 2200px)
{
	.table-results .wrapper { max-width: 1180px; margin: 0; margin-left: 9%;}
}
@media (max-width: 640px) { 
	.table-results { padding: 25px 0; }
	.table-results h5 { float: none; margin-right: 10px; position: absolute; right: 0px; }
	.table-results table { padding: 0 10px; margin-top: 25px; }
	.table-results table td { width: 640px; padding: 10px; }	
	.table-results .title, .table-results .owner, .table-results .filing-date  { text-align: left;}
	.table-results .filing-date { color: #7B848D; font-family: MontserratRegular;  font-size: 14px; line-height: 24px;
		display: inline-block; vertical-align: top;}
	.table-results .one-line { text-align: left; margin-top: 4px;}
	.table-results .status { display: inline-block; width: 60px; height: 22px; float: right;}
	.table-results .paging .wrapper { padding: 5px !important; }
	.table-results .paging .MuiPaginationItem-root.MuiPaginationItem-page { margin-right: 5px !important; }
}

.table-results .paging { text-align: center; margin-top: 70px; }
.table-results .paging .wrapper { border: 1px solid #ECEDF1; box-sizing: border-box; border-radius: 6px; display: inline-block; padding: 22px;}
.table-results .paging .MuiPaginationItem-previousNext { display: inline-block; color: #A5AAAF;}
.table-results .paging .MuiPaginationItem-previousNext:hover {  color: #F37217; }
.table-results .paging li:nth-of-type(1) .MuiPaginationItem-previousNext::after { content: "prev"; color: #A5AAAF; margin-left: 15px;font-family: MontserratRegular;  font-size: 14px; line-height: 24px; }
.table-results .paging li:nth-of-type(1) .MuiPaginationItem-previousNext:hover::after, 
	.table-results .paging li:nth-last-of-type(1) .MuiPaginationItem-previousNext:hover::before { color: #F37217;}
.table-results .paging li:nth-last-of-type(1) .MuiPaginationItem-previousNext::before { content: "next"; color: #A5AAAF; margin-right: 15px; }
 

.table-results .paging .next { color: #F37217; font-family: MontserratRegular;  font-size: 14px; line-height: 24px; display: inline-block;
	margin-left: 30px;}
.table-results .paging .prev::before { content: url('../public/paging-prev.svg'); margin-right: 15px; }
.table-results .paging .next::after { content: url('../public/paging-next.svg'); margin-left: 15px;}
.table-results .paging .MuiPaginationItem-root.MuiPaginationItem-page { color: #7B848D; font-family: MontserratBold;  font-weight: bold; font-size: 16px; line-height: 20px; 
	display: inline-block; margin-right: 15px; text-align: center;  width: 20px;}
.table-results .paging .page:nth-last-of-type(1) {  margin-right: 0px;}
.table-results .paging .MuiPaginationItem-root.Mui-selected { color: #03589C; font-family: MontserratBold;  font-weight: bold; font-size: 16px; line-height: 20px; 
	border-bottom: 3px solid #03589C; background-color: transparent; border-radius: 0px; }

.no-results { text-align: center; margin-bottom: 75px;padding-left: 20px; padding-right: 20px}
.no-results h2 { margin-top: 0px; margin-bottom: 15px;}
.no-results p { max-width: 500px; margin: 0 auto; color: #7B848D; font-size: 24px; line-height: 32px; margin-bottom: 35px; }
.no-results .orange-btn { max-width: 500px; padding: 24px 19px;}
.no-results .vector-btn-wrapper { position: relative; max-width: 500px; margin: 0 auto;}
.no-results .vector-btn-wrapper img { position: absolute; left: -320px; top: 45px;}

@media (max-width: 640px) {
	.no-results > img { width: 100%; max-width: 348px; }
}

/* Privacy Policy page */
.privacy { position: relative; }
.privacy .top-banner { background: url("../public/privacy-banner.jpg") no-repeat; background-size : cover; min-height: 450px; }	
.privacy .top-banner .copy { text-align: center; padding-top: 11%; }
.privacy .top-banner h1 { font-family: MontserratBold; font-style: normal;font-weight: 700; font-size: 48px; line-height: 59px; color: #fff;
	margin-bottom: revert; }
.privacy .text { max-width: 960px; margin: 0 auto; margin-top: 80px; margin-bottom: 75px;padding-left:20px; padding-right: 20px; text-align: justify;}
.privacy .text h2 { color: #7B848D; font-family: MontserratRegular;  font-size: 24px; line-height: 32px;}
.privacy .text h3 { color: #7B848D; font-size: 16px; line-height: 20px; margin-bottom: 30px}
.privacy .text p { color: #7B848D;  }
.privacy .text .tag { border-left: 3px solid #F37217; padding: 10px 5px 5px 45px; margin-top: 50px;}
.privacy .text .tag h4 { margin-top: 0px;}
.privacy .text h4 { color: #03589C;font-family: MontserratBold; margin-top: 48px;   }
.privacy .text hr { width: 90%; margin: 0 auto; margin-top: 35px;}
.privacy .text .tag .date {
	font-family: MontserratRegular;
	font-weight: lighter;
}
.privacy .text .tag .dateContainer {display: flex;gap: 20px; align-items: center}
.privacy .text .imageContainer {display: flex;justify-content: left;margin-bottom: 16px;}
.privacy .text .image { width: 50%;}
.privacy .text img {width: 100%;}
.articleHeader .top-banner h1 { font-family: MontserratBold; font-style: normal;font-weight: 700; font-size: 48px; line-height: 59px; color: #fff;
	margin-bottom: revert; }
.articleHeader .top-banner { background: url("../public/privacy-banner.jpg") no-repeat; background-size : cover; min-height: 110px; }
.articleHeader .top-banner .copy { text-align: center; padding-top: 2%; }
.article {   max-width: 960px;
	margin: 0 auto;
	margin-top: 80px;
	margin-bottom: 75px;
	padding-left: 20px;
	padding-right: 20px;
	text-align: justify;
	font-family: MontserratRegular;
	font-size: 14px;}
.article li {
	font-family: MontserratRegular;
	font-size: 14px;
	margin-top: 16px;
	margin-bottom: 16px;
}
.article ol {list-style: none; counter-reset: li}
.article ol > li::before {content: counter(li)".";counter-increment: li;	font-size: 1.2rem;
	font-weight: bold; display: inline-block; width: 1em; margin-left: -1.5em;
	margin-right: 0.5em; text-align: right;}
.article .text .boldHeader {	font-size: 1.2rem;font-weight: bold;}
.article ol > li::first-line {
	font-size: 1.2rem;
	font-weight: bold;
}
.article .date {
	margin-top: 16px;
	margin-bottom: 16px;
	font-family: MontserratRegular;
	font-weight: lighter;
	text-align: center;
}
.article .text h4 {
	color: #03589C;
	font-size: 28px;
	text-align: center;
	font-family: MontserratBold;
	margin-top: 48px;
}
.article .text .imageContainer {display: flex;justify-content: center;margin-bottom: 16px;}
.article .text .image { width: 50%;}
.article .text img {width: 100%;}
@media (max-width:768px) {
	.article {margin-top: 8px;}
	.article .text h4 {
		margin-top: 16px;
		line-height: 115%;
	}
}

/* FAQ page */
.faq-page { position: relative; }
.faq-page .top-banner { background: url("../public/faq-banner.jpg") no-repeat; background-size : cover; min-height: 450px; }	
.faq-page .top-banner .copy { text-align: center; padding-top: 8%; }
.faq-page .top-banner h1 { font-family: MontserratBold; font-style: normal;font-weight: 700; font-size: 48px; line-height: 59px; color: #fff;
	margin-bottom: 25px; }
.faq-page .top-banner p { font-family: MontserratBold; font-size: 24px;	line-height: 29px; color: #FFFFFF; max-width: 545px; margin: 0 auto;}
.faq-page .top-banner p a:link, .faq-page .top-banner p a:visited { color: #F37217;  } 
.faq-page .sides-wrapper { margin: 0 auto;  margin-top: 100px; margin-bottom: 100px; max-width: 1180px;}
.faq-page .sides-wrapper .left-side { display: inline-block; vertical-align: top; width: 60%; margin-right: 50px; }
.faq-page .sides-wrapper .right-side { display: inline-block; }
.faq-page .download-free { background-color: #ECEDF1; max-width: 400px; padding: 40px; position: relative;}
.faq-page .download-free h4 { color: #7B848D; font-family: MontserratRegular;  font-size: 24px; line-height: 36px; display: inline-block; margin-bottom: 20px;  }
.faq-page .download-free img { display: inline-block; vertical-align: top; position: relative; top: -18px; left: 13px; opacity: 0.5;}
.faq-page .download-free label { color: #394149; font-family: MontserratBold; font-size: 10px; line-height: 12px; margin-left: 5px;
	text-transform: uppercase;}
.faq-page .download-free input { background: #FFFFFF; border: 1px solid #A5AAAF; box-sizing: border-box; border-radius: 4px; width: 100%; margin-bottom: 10px;}
.faq-page .download-free p {  color: #7B848D; font-family: MontserratRegular;  font-size: 11px; line-height: 13px;}
.faq-page .download-free .orange-btn { text-align: center; margin-top: 30px; margin-bottom: 30px; }


/* Contact page */
.contact { position: relative; }
.contact .banner { background: url("../public/contactus-banner.jpg") no-repeat; background-size : cover; min-height: 920px; }	
.contact .left-side { width: 50%; display: inline-block; vertical-align: top;}
.contact .right-side { width: 50%; display: inline-block;   padding-top: 100px; padding-left: 70px;}
.contact .side-wrapper { border-bottom: 1px solid #ECEDF1; border-top: 1px solid #ECEDF1; }
.contact .form {max-width: 492px;}
.contact .form h1 { color: #03589C; font-family: MontserratBold; font-size: 48px; line-height: 59px; margin-bottom: 0px;}
.contact .form h2 { color: #03589C; font-family: MontserratRegular; font-size: 24px; line-height: 32px; margin-top: 0px;}
.contact .form  label { color: #394149; font-family: MontserratBold; font-size: 10px; line-height: 12px; margin-left: 5px;
	text-transform: uppercase;}
.contact .form  input, .contact .form  textarea { background: #FFFFFF; border: 1px solid #A5AAAF; box-sizing: border-box; border-radius: 4px; width: 95%; margin-bottom: 10px;	}
.contact .form .orange-btn { text-align: center; margin-top: 30px; margin-bottom: 30px; max-width: 160px;}

.contact .info { max-width: 900px; margin: 0 auto; margin-top: 30px; margin-bottom: 20px;}
.contact .info .info-box {max-width: 380px; border-right: 1px solid rgba(255, 168, 77, 0.5);  padding-right: 80px; padding-top: 25px; padding-bottom: 20px;
	display: inline-block; margin-left: 40px; vertical-align: top;}
.contact .info .info-box .icon { width: 48px; height: 48px; background-color: rgba(243, 114, 23, 0.1); text-align: center; border-radius: 25px;
	padding-top: 12px; display: inline-block; vertical-align: top; margin-right: 16px;}
.contact .info .info-box .copy { display: inline-block; padding-top: 6px; max-width: 233px; }
.contact .info .info-box h4 { color: #03589C; font-family: MontserratRegular; font-size: 24px; line-height: 32px; margin-top: 0px; text-transform: uppercase; }
.contact .info .info-box p { color: #7B848D; font-family: MontserratRegular; font-size: 14px; line-height: 24px;}
.contact .info .info-box:nth-of-type(1) {  margin-left: 0px; }
.contact .info .info-box:nth-last-of-type(1) { padding-right: 0px; border-right: 0px; }
.contact .form-group-email .form-control-loader { top: 38px; right: 8%; }

.contact-feedback { background-color: #ECEDF1; padding: 65px 0;}
.contact-feedback .react-multi-carousel-list { margin: 0 auto; max-width: 1680px; }
.contact-feedback .react-multiple-carousel__arrow { display: none;}
.contact-feedback .react-multi-carousel-dot-list {  position: inherit; margin-top: 20px; }

@media (max-width: 640px) { 
	.contact .left-side { width: 100%; }
	.contact .banner { min-height: 290px; text-align: center; padding-top: 31%;}
	.contact .banner h1, .contact .banner h2 { color: #FFF}
	.contact .banner h1 { font-family: MontserratBold; font-size: 41px; line-height: 41px; }
	.contact .banner h2 { font-family: MontserratRegular; font-size: 26px; line-height: 30px;
		margin-top: 0px; }
	.contact .right-side { width: 100%; padding: 20px; }
	.contact .form .orange-btn { max-width: inherit; }
	.contact .form input, .contact .form textarea { width: 100%; }
	.contact .info .info-box { margin-left: 20px !important; padding-right: 0px; border-right: 0px;
		padding-bottom: 0px; }
	.contact .form-group-email .form-control-loader { right: 4%; }
}

/* Pricing page */
.pricing { position: relative; }
.pricing .top-banner { background: url("../public/pricing-banner.jpg") no-repeat; background-size : cover; min-height: 450px; }	
.pricing .top-banner .copy { text-align: center; padding-top: 10%; }
.pricing .top-banner h1 { font-family: MontserratBold; font-style: normal;font-weight: 700; font-size: 48px; line-height: 59px; color: #fff;
	margin-bottom: revert; }

.pricing .packages { text-align: center; max-width: 1400px; margin: 0 auto; padding: 65px 0; }
.pricing .packages h2 { margin-bottom: 10px; }
.pricing .packages h3 {  color: #7B848D; font-family: MontserratRegular; font-size: 18px; line-height: 22px; margin-bottom: 24px;  }
.package-box { display: inline-block;width: 33%;text-align: center;}
.package-box:nth-last-of-type(1) { margin-right: 0px;}
.package-box h4 {text-transform: uppercase; color: #7B848D; font-family: MontserratBold; font-size: 14px; line-height: 17px; 
	background-color:rgba(236, 237, 241, 0.5); padding: 8px 0;}
.package-box .content { padding: 0px 5px;}
.package-box h5 { margin-bottom: 5px; color: #7B848D; font-family: MontserratBold; font-size: 1.2em; line-height: 13px; margin-top: 15px;  }
.package-box p { color: #A5AAAF; font-family: MontserratRegular; font-size: 0.9em; line-height: 24px; }
.package-box .price {color: #394149; font-family: MontserratBold; font-size: 1em; line-height: 37px;  }
.package-box .getstarted-btn {color: #03589C;
	font-family: MontserratBold;
	font-size: 0.55em;
	line-height: 24px;
	padding: 5px 0px;
	width: 100%;
	display: inline-block;
	border-radius: 4px;
	text-decoration: none;
	cursor: pointer;
	margin-top: 15px;
}
.package-box:nth-of-type(1) .getstarted-btn { border: 2px solid #ECEDF1;padding: 4px 0px;}
.package-box:nth-of-type(3) .getstarted-btn { background: linear-gradient(93.51deg, #44CDC8 0%, #0D3696 100%);color: #fff;}
.package-box:nth-of-type(2) .getstarted-btn { background: linear-gradient(180deg, #FFA84D 0%, #F37217 100%);color: #fff; }
.package-box:nth-of-type(2) h5 { color: #FC983D; }
.package-box:nth-of-type(3) h5 { color: #1A58A2; }
.package-box hr { background-color: #ECEDF1; margin-top: 40px; margin-bottom: 0px;}
.package-box .feature { border-bottom: 1px dashed #ECEDF1; text-align: left; }
.package-box .feature span { color: #7B848D; font-family: MontserratBold; font-size: 14px; line-height: 24px; margin-left: 10px; }
.package-box .feature img { float: right; margin-right: 10px;}

.package-box:nth-of-type(2) h4 { background: linear-gradient(90.01deg, #FFA84D 0%, #F37217 100%); color: #fff; }
.package-box:nth-of-type(3) h4 { background: linear-gradient(270.01deg, #0D3696 0%, #44CDC8 100%); color: #fff; }



.package-box:nth-of-type(2) h4 span { content: url("../public/Star 1.png"); }
.package-box:nth-of-type(2) h4 span.left { margin-right: 7px; }
.package-box:nth-of-type(2) h4 span.left:nth-of-type(3) { opacity: 0.75; }
.package-box:nth-of-type(2) h4 span.left:nth-of-type(2) { opacity: 0.5; }
.package-box:nth-of-type(2) h4 span.left:nth-of-type(1) { opacity: 0.35; }
.package-box:nth-of-type(2) h4 span.right { margin-left: 7px; }
.package-box:nth-of-type(2) h4 span.right:nth-of-type(6) { opacity: 0.75; }
.package-box:nth-of-type(2) h4 span.right:nth-of-type(7) { opacity: 0.5; }
.package-box:nth-of-type(2) h4 span.right:nth-of-type(8) { opacity: 0.35; }

.package-box:nth-of-type(3) .feature:nth-of-type(9) span { width: 51%; display: inline-block; margin-top: -10px;}



.package-box2 { display: inline-block;width: 33%;text-align: center;}
.package-box2:nth-last-of-type(1) { margin-right: 0px;}
.package-box2 h4 {text-transform: uppercase; color: #7B848D; font-family: MontserratBold; font-size: 14px; line-height: 17px;
	background-color:rgba(236, 237, 241, 0.5); padding: 8px 0;}
.package-box2 .content { padding: 0px 5px;}
.package-box2 h5 { margin-bottom: 5px; color: #7B848D; font-family: MontserratBold; font-size: 1.2em; line-height: 13px; margin-top: 15px;  }
.package-box2 p { color: #A5AAAF; font-family: MontserratRegular; font-size: 0.9em; line-height: 24px; }
.package-box2 .price {color: #394149; font-family: MontserratBold; font-size: 1em; line-height: 37px;  }
.package-box2 .getstarted-btn {color: #03589C;
	font-family: MontserratBold;
	font-size: 0.55em;
	line-height: 24px;
	padding: 5px 0px;
	width: 100%;
	display: inline-block;
	border-radius: 4px;
	text-decoration: none;
	cursor: pointer;
	margin-top: 15px;
}
.package-box2:nth-of-type(1) .getstarted-btn { border: 2px solid #ECEDF1;padding: 4px 0px;}
.package-box2:nth-of-type(3) .getstarted-btn { background: linear-gradient(93.51deg, #44CDC8 0%, #0D3696 100%);color: #fff;}
.package-box2:nth-of-type(2) .getstarted-btn { background: linear-gradient(180deg, #FFA84D 0%, #F37217 100%);color: #fff; }
.package-box2:nth-of-type(2) h5 { color: #FC983D; }
.package-box2:nth-of-type(3) h5 { color: #1A58A2; }
.package-box2 hr { background-color: #ECEDF1; margin-top: 40px; margin-bottom: 0px;}
.package-box2 .feature { border-bottom: 1px dashed #ECEDF1; text-align: left; }
.package-box2 .feature span { color: #7B848D; font-family: MontserratBold; font-size: 14px; line-height: 24px; margin-left: 10px; }
.package-box2 .feature img { float: right; margin-right: 10px;}

.package-box2:nth-of-type(2) h4 { background: linear-gradient(90.01deg, #FFA84D 0%, #F37217 100%); color: #fff; }
.package-box2:nth-of-type(3) h4 { background: linear-gradient(270.01deg, #0D3696 0%, #44CDC8 100%); color: #fff; }



.package-box2:nth-of-type(2) h4 span { content: url("../public/Star 1.png"); }
.package-box2:nth-of-type(2) h4 span.left { margin-right: 7px; }
.package-box2:nth-of-type(2) h4 span.left:nth-of-type(3) { opacity: 0.75; }
.package-box2:nth-of-type(2) h4 span.left:nth-of-type(2) { opacity: 0.5; }
.package-box2:nth-of-type(2) h4 span.left:nth-of-type(1) { opacity: 0.35; }
.package-box2:nth-of-type(2) h4 span.right { margin-left: 7px; }
.package-box2:nth-of-type(2) h4 span.right:nth-of-type(6) { opacity: 0.75; }
.package-box2:nth-of-type(2) h4 span.right:nth-of-type(7) { opacity: 0.5; }
.package-box2:nth-of-type(2) h4 span.right:nth-of-type(8) { opacity: 0.35; }

.package-box2:nth-of-type(3) .feature:nth-of-type(9) span { width: 51%; display: inline-block; margin-top: -10px;}


/* registration page */
.reg { position: relative; }
.reg .banner { background: url("../public/registration-banner.jpg") no-repeat; background-size : cover; min-height: 984px; background-position: center;
	position: relative;}	
.reg .left-side { width: 40%; display: inline-block; vertical-align: top; position: relative;}
.reg .right-side { width: 59%; display: inline-flex;   padding-top: 25px; padding-left: 30px;}
.reg .right-side-main { width: 100%; display: inline-flex; padding-top: 25px; padding-left: 0px; justify-content: center;}
.reg .side-wrapper { border-bottom: 1px solid #ECEDF1; border-top: 1px solid #ECEDF1; }
.reg .form {max-width: 85%; margin-bottom: 70px;}
.reg .reg-header { margin-bottom: 50px;}
.reg .reg-header h1 { color: #03589C; font-family: MontserratBold; font-size: 36px; line-height: 48px; margin-bottom: 0px;}
.reg .reg-header h2 { color: #03589C; font-family: MontserratBold; font-size: 26px; line-height: 32px; margin-top: 0px; margin-bottom: 20px;}
.reg .reg-header p { color: #03589C; font-family: MontserratRegular; font-size: 16px; line-height: 24px; margin-top: 0px;}
.reg .reg-header p a:link, .reg .reg-header p a:visited { color: #F37217; font-family: MontserratBold; font-size: 16px; line-height: 24px; 
	text-decoration: none; } 

	@media (max-width: 1700px) {
		.reg .form {max-width: 100%; }
	}

	@media (max-width: 810px) {
		.reg .banner { background: url("../public/registration-banner-m.jpg") no-repeat; min-height: 0px;	}
		.reg .left-side { width: 100%; display: none; }
		.reg .right-side { width: 100%; display: inline-block; padding-left: 0px; padding-top: 12px;}
		.reg .form { max-width: 100%; margin-bottom: 70px;	}
		.reg .reg-header { text-align: center;margin-bottom: 0px; }
		.reg .reg-header h2 { font-size: 17.5px; line-height: 24px; margin-bottom: 0px; }
		.reg .reg-header p { font-size: 13px;}
		.reg .reg-header p a:link, .reg .reg-header p a:visited { font-size: 12px;}
	}

.reg .MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary { background-color: #F37217; color: #fff; font-family: MontserratBold;font-size: 14px; line-height: 24px;
	border: 0px; text-transform: none; padding: 14px 45px;}
.reg .MuiButton-root.MuiButton-outlined.MuiButton-outlinedPrimary.prev { background-color: #FFF; color: #03589C; font-family: MontserratBold; font-size: 14px; line-height: 24px; 
	border: 2px solid #ECEDF1; text-transform: none; padding: 14px 45px; margin-right: 13px;}
.reg .MuiButton-root.addOwner { background-color: #FFF; color: #03589C; font-family: 'Open SansVariable'; font-weight: 600;font-size: 12px; line-height: 15px;
	border: 2px solid #ECEDF1; text-transform: none; padding: 9px 19px; margin-left: 10px;
	border-radius: 4px; display: inline-block;}
	
	.reg .question h2 { color: #03589C; font-family: 'Open SansVariable'; font-size: 22px; line-height: 27px; margin-bottom: 15px;  }
	.reg .question p, .reg  .question .form-label { color: #000;font-family: 'Open SansVariable'; font-weight: 400; font-size: 1em; line-height: 20px; margin-bottom: 10px; }
.reg  .question .two-column-form .form-label { font-size: 10px !important; line-height: 12px !important; text-transform: uppercase; margin-bottom: 0px !important; padding-left: 10px; }
.reg .question p.regular { font-family: 'Open SansVariable'; font-size: 14px; line-height: 24px; }
.reg .question .form-group { margin-bottom: 40px !important; }
.reg .text-muted.form-text { color: #7B848D; font-family: 'Open SansVariable'; font-size: 14px; line-height: 24px; }
.reg .form-check-inline { color: #394149; font-family: 'Open SansVariable'; font-size: 14px; line-height: 24px; }
.reg .MuiStepper-root { margin-bottom: 20px;}
.reg .summary { background-color: #fff; width: 500px; min-height: 596px; box-shadow: 0px 7px 15px rgba(168, 168, 168, 0.12); position: absolute; right: 7%; top: 7%;}
.reg .summary h3 { color: #03589C;font-family: 'Open SansVariable'; font-weight: 600; font-size: 24px; line-height: 32px; margin-bottom: 0px; border-bottom: 3px solid rgba(236, 237, 241, 0.5)  ;
padding: 15px 42px;  }
.reg .summary .step-box { border-bottom: 1px solid #ECEDF1; padding: 20px; padding-bottom: 15px; margin: auto 25px; }
.reg .summary .step-box h5 { color: #394149; font-family: 'Open SansVariable'; font-weight: 600; font-size: 12px; line-height: 15px;  }
.reg .summary .step-box p { color: #394149; font-family: 'Open SansVariable'; font-size: 14px; line-height: 24px;  margin-bottom: 3px;}
.reg .support-box { background: url("../public/reg-live-chat.png") no-repeat; width: 254px; height: 294px; background-size: contain; position: absolute;
left: calc(100% - 36vw); left:-webkit-calc(100% - 36vw); left:-moz-calc(100% - 36vw);
top: calc(100% - 3vw); top: -webkit-calc(100% - 3vw); top: -moz-calc(100% - 3vw);
text-align: center; padding-top: 41%;}
.reg .support-box .chat {  color: #03589C; font-family: MontserratBold; font-size: 18px; line-height: 32px; border-bottom: 3px solid #F37217; padding-bottom: 0px;
display: inline-block; text-decoration: none; cursor: pointer;}
.reg .support-box .contact-us { display: block; background: #03589C; border-radius: 4px; color: #fff; width: 130px;
height: 42px;text-align: center; margin: 0 auto; padding: 10px 18px; font-family: MontserratBold; margin-top: 20px;
text-decoration: none;}

.reg .package-box { width: 31%; margin-right: 15px;margin-bottom: 32px;}
.reg .package-box.selected { box-shadow: 0px 14px 24px rgba(3, 88, 156, 0.8);  }
.reg .box-wrapper { width: 100%;}
.reg .package-box h4 { font-size: 9px; line-height: 12px; }
.reg .package-box h4 span { width: 10px; }
.reg .package-box h5 { margin-top: 0px;}
.reg .package-box:nth-of-type(2) h4 span.left { margin-right: 3px; }
.reg .package-box:nth-of-type(2) h4 span.right { margin-left: 3px; }
.reg .package-box hr { margin-top: 25px; margin-bottom: 7px; }

.reg .package-box2 { width: 31%; margin-right: 15px;margin-bottom: 32px;}
.reg .package-box2.selected { box-shadow: 0px 14px 24px rgba(3, 88, 156, 0.8);  }
.reg .package-box2 h4 { font-size: 10px; line-height: 12px; }
.reg .package-box2 h4 span { width: 10px; }
.reg .package-box2 h5 { margin-top: 0px;}
.reg .package-box2:nth-of-type(2) h4 span.left { margin-right: 3px; }
.reg .package-box2:nth-of-type(2) h4 span.right { margin-left: 3px; }
.reg .package-box2 hr { margin-top: 25px; margin-bottom: 7px; }

.reg .packages #TrademarkForm {display: none;}

@media (max-width: 1345px) {
.reg .support-box {
    left: calc(100% - 31vw); left:-webkit-calc(100% - 31vw); left:-moz-calc(100% - 31vw);
    top: calc(100% - 6vw); top: -webkit-calc(100% - 6vw); top: -moz-calc(100% - 6vw);
    padding-top: 59%;
}
}
@media (max-width: 1345px) {
.reg .summary { width: auto; }
}
@media (min-width: 1345px) and (max-width: 1585px) {
.reg .left-side { width: 41%; }
}
@media (min-width: 1225px) and (max-width: 1344px) {
.reg .left-side { width: 35%; }
}
@media (min-width: 1140px) and (max-width: 1224px) {
.reg .left-side { width: 30%; }
}
@media (min-width: 1025px) and (max-width: 1139px) {
.reg .left-side { width: 25%; }
}

@media (max-width: 1345px) {
.reg .package-box { width: 33%; margin-right: 0px; margin-bottom: 32px}
.reg .package-box2 { width: 33%; margin-right: 0px; margin-bottom: 32px}
}

div.polaroid {
	padding: 10px 10px 10px 10px;
	border: 1px solid #BFBFBF;
	background-color: white;
	margin-bottom: 20px;
	box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
div.row {margin-right:-5px;}

.reg .search-product-item label { color: #394149; font-family: MontserratBold; font-size: 16px; line-height: 20px;}
.reg .search-product-item p { color: #7B848D; font-family: MontserratRegular; font-size: 14px; line-height: 24px; }
.reg .search-product-item.selected label, .reg .search-product-item.selected p { color: #03589C; }
.reg .search-product-item span.mp-label { color: #00663b !important;}
.reg .search-product-item span.r-label { color: #44cdc8 !important;}

label[for="literalElements"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="colorsInLogo"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="markDesc"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="translation"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="goodsServiceDetail"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="serialNumber"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="markOwnerFirstName"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="markOwnerLastName"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="markOwnerEmail"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="markOwnerPhone"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="classesGoodsNotCovered"] { font-size: 10px !important; line-height: 12px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="itemsCovered"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="itemsExtension"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="firstName"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="lastName"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="phone"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="email"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="trademarkName"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="service"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="ownName"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="officeActionStatus"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="classesGoodsNotCovered"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="itemsCovered"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="itemsExtension"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="numberOfExtensionsFiled"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="extensionReason"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="extensionReasonOther"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="statementOfUseStatus"] { font-size: 10px !important; line-height: 18px !important; text-transform: uppercase; margin-bottom: 7px !important; padding-left: 10px; }
label[for="individualOrgOrInd"] { font-family: MontserratRegular !important; font-size: 14px !important; line-height: 24px !important; margin-bottom: 20px; }

.ownerTop { margin-bottom: 35px;}
.ownerTop .form-check.form-check-inline { margin-right: 50px;}
select.form-control { font-size: 14px; padding: 8px 12px; height: 48px; border-radius: 4px; border: 1px solid #A5AAAF; }
textarea.form-control { border-radius: 4px; border: 1px solid #A5AAAF; }

.reg .step-3 .MuiButton-root.addOwner {margin-left: 0px;}
.reg .step-3 { margin-bottom: 50px;}
.reg .step-3 .MuiPaper-root.MuiPaper-elevation { box-shadow: none; }

.one-column-form { padding: 0px 15px; }
.one-column-form label { padding: 0 0 0 10px; }

.two-column-form { padding-right: 0px; padding-top: 0px; padding-bottom: 0px; }
.two-column-form .form-control { margin-right: 26px; }
.two-column-form .form-control-loader { right: 36px };
.two-column-form label { font-size: 10px !important; line-height: 12px !important; text-transform: uppercase; margin-bottom: 0px !important; padding-left: 10px; }
.two-column-form label.form-label { font-size: 10px !important; line-height: 12px !important; text-transform: uppercase; margin-bottom: 0px !important; padding-left: 10px; }
.two-column-form .form-control { border-radius: 4px; border: 1px solid #A5AAAF; background: #FFFFFF; height: 48px;
padding: 5px 15px; }
.mt-3 {
padding-left: 0px;
}
.col-sm-4 {
padding-left: 0px;
}
.col-12 {
padding-left: 0px;
}
@media (max-width: 960px) {
.two-column-form .form-control, .two-column-form #email.form-control { max-width: 100%; margin-right: 0; }
.two-column-form .form-control-loader { right: 10px; }
.reg .package-box2 { width: 100%;}
}

.step-6.question h2 { margin-bottom: 35px;  }
.rush-processing {background-color: #FFFFFF;	border: 1px solid rgba(3, 88, 156, 0.15);	box-sizing: border-box;
box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.08); padding: 20px 22px; margin-bottom: 20px;
position: relative;}
.reg .question .rush-processing h2 { font-size: 14px !important; line-height: 17px !important; text-transform: uppercase; margin-bottom: 15px;}
.reg .question .rush-processing p.regular { font-size: 14px !important; line-height: 24px !important; margin-bottom: 23px;}
.rush-processing .required { color: #F37217; font-family: 'Open SansVariable'; font-weight: 600; font-size: 12px; line-height: 15px;}
.rush-processing .hour-24 { color: #333; font-family: 'Open SansVariable'; font-weight: 600; font-size: 12px; line-height: 15px;}
.rush-processing .price { color: #F37217; font-family: 'Open SansVariable'; font-weight: 600; font-size: 12px; line-height: 15px;}
.reg .question .rush-processing img { display: inline-block; margin-top: 11px; }
.reg .question .rush-processing .copy { display: inline-block; width: 95%;  vertical-align: top; margin-left: 1.4%; }
.reg .question .rush-processing .optional { background:url("../public/optional.png") no-repeat ; width: 68px; height: 68px; position: absolute; top:-4px; right: -4px;}
.agreement-cbx .required { color: #F37217; font-family: MontserratRegular; font-size: 14px; line-height: 24px;}
.agreement-cbx .label { font-family: MontserratRegular; font-size: 14px; line-height: 24px;}
.scroll-text { background: #FFFFFF; border: 1px solid #A5AAAF; box-sizing: border-box; border-radius: 4px;
padding: 18px 24px; margin-top: 25px; margin-bottom: 50px;}
.scroll-text .bold { font-family: MontserratBold !important;}

/* checkout */
.checkout .banner { background: url("../public/checkout-banner.jpg") no-repeat; background-size : cover; min-height: 350px; background-position: center;}	
.checkout .banner img {float: right; margin-right: 17%; margin-top: 3%;}
.checkout .sides-wrapper { }
.checkout .sides-wrapper .left-side { display: inline-block; vertical-align: top; width: 60%;  }
.checkout .sides-wrapper .right-side { display: inline-block; background-color:#ECEDF1; width: 40%; padding-bottom: 100px;}

.checkout .summary { background-color: #fff; max-width: 400px; padding-bottom: 16px; box-shadow: 0px 7px 15px rgba(168, 168, 168, 0.12); margin-left: 70px; margin-top: 100px;
	border-top: 5px solid #FFA84D;}
.checkout .summary h3 { color: #394149; font-family: MontserratBold; font-size: 24px; line-height: 32px; margin-bottom: 0px; border-bottom: 3px solid rgba(236, 237, 241, 0.5)  ; 
	padding: 40px 42px; text-align: center;  }
.checkout .summary .step-box { border-bottom: 1px solid #ECEDF1; padding: 20px; padding-bottom: 15px; margin: auto 25px; }
.checkout .summary .step-box h5 { color: #7B848D; font-family: MontserratBold; font-size: 12px; line-height: 15px;  }
.checkout .summary .step-box.subtotal h5 { color: #03589C; } 
.checkout .summary .step-box p { color: #7B848D; font-family: MontserratRegular; font-size: 14px; line-height: 24px;  margin-bottom: 3px;}

.checkout .monay100-wrapper { margin-top: 40px; display: flex; max-width: 400px; margin-left: 70px; }
.checkout .monay100-wrapper img { max-width: 140px;margin-left: auto; margin-right: auto; }
.checkout .undermonay-wrapper { max-width: 400px; margin-top: 20px; margin-left: 70px; }

.checkout .btn-wrapper { text-align: center;  color: #7B848D; font-family: MontserratRegular; font-size: 12px; line-height: 15px; margin-top:9px; margin-bottom: 9px;}
.checkout .btn-wrapper span { background: url("../public/locky.png") no-repeat; background-size : cover; width: 12px; height: 13px; display: inline-block; margin-right: 10px;}
.checkout .pay { background: linear-gradient(180deg, #FFA84D 0%, #F37217 100%);	border-radius: 4px;
	color: #FAFAFF; font-family: MontserratBold; font-size: 14px; line-height: 24px; 
	padding: 14px; width: 210px; border: 0px; }

.checkout h1 { color: #03589C; font-family: MontserratBold; font-size: 36px; line-height: 44px;   }
.checkout .left-side h5 { color: #7B848D; font-family: MontserratRegular; font-size: 18px; line-height: 22px; margin-bottom: 40px; }
.checkout .left-side .copy { padding-top: 100px; padding-right: 140px; float: right; width: 75%; max-width: 1000px;}

.checkout .security-field { position: relative; }
.checkout .security-field > .security-eye-icon { position: absolute; top: 39px; right: 7px; cursor: pointer; }
.checkout .security-field.validated > .security-eye-icon { right: 30px; }
.checkout .was-validated .security-field > .security-eye-icon {right: 30px;}
.checkout .payment-method .agreement-cbx .form-check-label {white-space: normal;}

.payment-method label  { color: #394149; font-family: MontserratBold; font-size: 10px; line-height: 12px; text-transform: uppercase; margin-left: 10px; white-space: nowrap; }
.payment-method input {background: #FFFFFF; border: 1px solid #A5AAAF; box-sizing: border-box; border-radius: 4px; padding: 8px 13px; height: 48px;} 

.payment-method .mr-wrapper .row { margin-right: 10px; }
.payment-method .ml-wrapper label { padding-left: 0px !important; }
.payment-method div.row {margin-right:-10px;}
/* thank you */
.thankyou.checkout h1 {color: #394149; margin-bottom: 30px;}
.thankyou.checkout .summary { border-top: 5px solid #03589C; }
.thankyou.checkout .summary h3 { color: #03589C; }
.thankyou.checkout .h4 { color: #F37217; font-family: MontserratBold; font-size: 12px; line-height: 15px; text-transform: uppercase; margin-top: 40px; }
.thankyou.checkout .p { color: #394149; font-family: MontserratRegular; font-size: 14px; line-height: 24px; }

.mobile {display: none !important;}
@media (max-width: 640px) { 	
	.desktop {display: none !important;}
	.mobile {display: block !important;}

	.home .top-banner {  background: url('../public/home-banner-m.jpg') no-repeat; min-height: 414px; }			
	.home .top-banner h1 { font-size: 1.2rem; line-height: 1.5em; }
	.top-banner .copy { max-width: 100%; }
	.home .top-banner input, .home .top-banner .orange-btn { max-width: 450px;	
			margin-right: 0px; width: 80%; margin: 0 auto; margin-bottom: 20px;	}
	.top-banner .bottom { margin-top: 1em; font-size: 1em; line-height: 1em; }
	.testimonials .copy { text-align: center; margin-right: 0px; margin-top: 0%; }
	.testimonials .copy h2 { margin-top: inherit; }
	.testimonials .t-boxs-wrapper { margin-right: 0px; }
	.testimonials .content-wrapper { padding-bottom: 117%; background-position: center bottom; }

	.protect-banner {
		background: url('../public/monay-bg-m.jpg') no-repeat center / contain; background-size: cover; min-height: 486px; }

	.home .step-process { padding: 20px; }
	.step-process { padding: 20px 50px 20px 45px; }
	.step-process .next { content: url('../public/arrow-down.svg'); margin-bottom: 8%; }
	.step-process.protect .step-box {margin-right: 0px; }
	.step-process .step-box { margin: 0 auto; }
	.step-process h4 {	margin-top: 30px; }

	.faq .left-side {width: auto; display: block; padding: 0 20px; text-align: center; }
	.faq img.lockup { width: 85%; }
	.faq .right-side { width: auto;	 display: block; margin-left: 0%; }

	.dont-wait-banner { background: url('../public/dont-wait-bg-m.jpg') no-repeat center / contain; height: 300px; background-size: cover; }
	.dont-wait-banner .copy { padding: 40px; }
	.dont-wait-banner h2 { font-size: 27px; margin: 0 auto; margin-bottom: 20px; width: auto; }
	.dont-wait-bottom-text {padding-left: 20px; padding-right: 20px; font-size: 12px; line-height: 18px;}
	.dont-wait-container {margin-top: 65px;  margin-bottom: 70px;}
	
	.checkout .banner { background: url('../public/checkout-banner-m.jpg') no-repeat; min-height: 250px; }
	.checkout .banner img { margin-right: 6%;margin-top: 34%;	width: 26%; }
	.checkout .summary { margin-top: 40px; margin-left: 20px; margin-right: 20px;  max-width: 100%; }
	.checkout .monay100-wrapper { margin-top: 6%; display: flex; max-width: 100%; margin-left: auto;  margin-right: auto;}
	.checkout .undermonay-wrapper { max-width: 400px; margin-top: 20px; margin-left: 20px; margin-right: 20px; }

	.checkout .sides-wrapper .right-side, .checkout .sides-wrapper .left-side { width: 100%; }
	.checkout .sides-wrapper .left-side { padding: 20px;  }
	.checkout .left-side .copy { padding-top: 0px;padding-right: 0px; float: none;	width: auto; }
	.thankyou.checkout h1, .checkout h1 { margin-bottom: 10px; text-align: center; font-size: 23px;line-height: 34px; } 
	.checkout .left-side h5 { text-align: center; margin-bottom: 25px;}

	.payment-method .mr-wrapper .row, .payment-method .mr-wrapper { margin-right: 12px !important; padding-right: 0px; }

	.privacy .top-banner { background: url("../public/privacy-banner.jpg") no-repeat; background-size : cover; min-height: 200px; }
}
@media (min-width: 769px) and (max-width: 1024px) { /* iPad Pro*/	
	.home.top-banner { padding-top: 22px; padding-left: 7%; }
	.top-banner .copy { max-width: 61vw; }
	.home.top-banner p { margin-bottom: 30px; }
}
@media (min-width: 640px) and (max-width: 768px) { /* iPad */	

}

.ct-search .banner { background: url("../public/ct-search-banner.jpg") no-repeat; background-size : cover; min-height: 507px; background-position: center;
	padding-top: 60px; padding-bottom: 55px;}	
.ct-search .banner h1 { font-family: MontserratBold; font-style: normal; font-size: 48px; line-height: 59px; color: #fff;
	margin-bottom: 25px; width: 80%; }
.ct-search .banner .copy-wrapper { max-width: 1200px; margin: 0 auto;}
.ct-search .banner .copy { text-align: left; width: 60%; }
.ct-search .banner p { font-family: MontserratRegular; font-size: 18px;	line-height: 22px; color: #FFFFFF; }
.ct-search .banner ul {padding-left: 0px; margin-bottom: 30px;}
.ct-search .banner li { font-family: MontserratRegular; font-size: 16px;	line-height: 34px; color: #FFFFFF; 
	list-style: none; }
	.ct-search .banner li span { background: url("../public/cts-check.png") no-repeat; width: 20px; height: 20px; 
		display: inline-block; margin-right: 20px; vertical-align: middle;}
	.ct-search .banner li p { display: inline-block;}
.ct-search .banner a:link, .ct-search .banner a:visited {
	border-radius: 4px; max-width: 220px !important; text-align: center; margin-right: 10px;
	text-decoration: none; } 
	.ct-search .banner a:link:hover { color: #FFF; }
.ct-search .banner a:link:nth-of-type(2) { background: transparent; border: 1px solid #FFFFFF;
	box-sizing: border-box;	border-radius: 4px;}

	@media (max-width: 640px) { 
		.ct-search .banner { background: url("../public/ct-search-banner-m.jpg") no-repeat; min-height: 600px;
			padding-top: 40px; background-size : cover; }	
		.ct-search .banner .copy { width: auto;     padding: 0px 20px; }
		.ct-search .banner h1 { font-size: 28px; line-height: 34px; width: auto; margin-bottom: 10px;}
		.ct-search .banner p { font-size: 14px;}
		.ct-search .banner li { font-size: 14px; line-height: 30px;  }
		.ct-search .banner li span { vertical-align: top;	margin-top: 5px;}
		.ct-search .banner li p { width: 75%;}
		.ct-search .banner a:link { margin-bottom: 10px;}
		.ct-search .banner ul {margin-bottom: 0px; }
	}

.fast-easy { background: #FAFAFF; padding: 40px 0; border-bottom: 2px solid #ECEDF1; text-align: center;}
.fast-easy .wrapper { margin-top: 65px;}
.fast-easy h2 { color: #394149; font-family: MontserratBold; font-size: 22px; line-height: 27px; margin-bottom: 15px; }
.fast-easy h3 { color: #03589C; font-family: MontserratRegular; font-size: 18px; line-height: 22px; }

.fast-easy .shield-box { background: url('../public/shield.png'); background-size: contain; width: 57px;height: 64px; 
	display: inline-block; vertical-align: middle; font-family: MontserratBold; font-size: 24px; line-height: 32px; color: #FFFFFF; 
	text-align: center; padding-top: 16px; }

.fast-easy .fast-box { display: inline-block; max-width: 290px; vertical-align: top; }
.fast-easy .arrow { background: url('../public/arrow-right-orange.svg') no-repeat; display: inline-block; 
	width: 9px;  height: 14px; margin-top: 1%; margin-right: 3%; margin-left: 3%;}
.fast-easy .fast-box:nth-last-child(1) {margin-right: 0%;}

.fast-easy .fast-box p {color: #7B848D; font-family: MontserratRegular; font-size: 14px; line-height: 24px;
	margin-top: 25px;}
.fast-easy .fast-box p.orange { color:#F37217; font-family: MontserratBold; font-size: 16px; line-height: 20px; 
	margin-top: 25px;  }

	@media (max-width: 640px) { 
		.fast-easy { padding: 20px; }
		.fast-easy .arrow { background: url('../public/arrow-down.png') no-repeat; display: block; 
			margin: 20px auto; width: 10px;  height: 6px; }
		.fast-easy .wrapper { margin-top: 40px; }
	}

.ct-search	.faq { padding-top: 90px; }

.sides { background: url('../public/loopa.png') no-repeat 15% 15%; background-color: #ECEDF1;}
.search-price {
	z-index: 999999;
	right: 30px;
	top: 43px;
	position: absolute;
	margin: auto 0;
	background-color: white;
}
.sides .sides-wrapper { max-width: 1200px; margin: 0 auto;}
.sides .sides-wrapper .left-side { display: inline-block; vertical-align: top; max-width: 620px; margin-right: 50px;
	padding-top: 123px; padding-bottom: 95px;}
.sides .sides-wrapper .right-side { display: inline-block; }
.sides .sides-wrapper .left-side h3 { color:#7B848D; font-family: MontserratBold; font-size: 36px; line-height: 44px; 
	margin-bottom: 15px;}
.sides .sides-wrapper .left-side p { color:#7B848D; font-family: MontserratRegular; font-size: 18px; line-height: 22px; 
	margin-bottom: 50px;}
.sides .sides-wrapper .left-side input { background: #FFFFFF; border: 1px solid #03589C; box-sizing: border-box;	
	box-shadow: 0px 7px 15px rgba(168, 168, 168, 0.12); border-radius: 4px;
	color: #394149; font-family: MontserratRegular; font-size: 14px; line-height: 24px;
	display: block; width: 100%; margin-bottom: 10px;
 }
	.sides .sides-wrapper .left-side textarea { background: #FFFFFF; box-shadow: 0px 7px 15px rgba(168, 168, 168, 0.12);
		color: #394149; border-radius: 4px; padding: 14px 19px; width: 100%;}
.sides .side-box { width: 500px;  background: #FFFFFF; box-shadow: 0px 7px 15px rgba(168, 168, 168, 0.12); 
	padding: 42px; margin-top: 75px; text-align: center; margin-bottom: -30px; border-radius: 16px;
	box-shadow: 0px 7px 15px rgba(168, 168, 168, 0.12); position: relative; padding-top: 80px; }
.sides .side-box .header-line { background: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%), #03589C;
	box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.11); position: absolute;
	left: 0%; right: 0%; top: 0%; bottom: 97.49%; border-radius: 16px 16px 0 0; }
.sides .side-box h4 { color:#03589C; font-family: MontserratBold; font-size: 24px; line-height: 29px; margin-bottom: 20px; text-align: left;}
.sides .side-box h5 { color:#394149; font-family: MontserratBold; font-size: 12px; line-height: 15px; text-transform: uppercase; text-align: left;}
.sides .side-box .orange-box { padding: 28px;  background-color: #F37217; border-top: 7px solid #CE6115; margin-top: 30px; margin-bottom: 20px;
	text-align: center; border-radius: 7px;	}
.sides .side-box .orange-box .star { background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.98) 100%); background-image: url("../public/Star 9.png"); background-repeat: no-repeat; 
		width: 48px; height: 48px; display: inline-block; position: relative; top: 7px; }
.sides .side-box .orange-box .price-wrapper { display: inline-block; vertical-align: middle; padding: 0 15px;}
.sides .side-box .orange-box .price { color:#FFFFFF; font-family: MontserratExtraBold; font-size: 48px; line-height: 59px; 
	background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.8) 100%); letter-spacing: 0.02em;
	-webkit-background-clip: text; -webkit-text-fill-color: transparent; background-clip: text;	
	text-fill-color: transparent;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.13);
	}
.sides .side-box .orange-box p { color:#FFFFFF !important; font-family: MontserratRegular; font-size: 18px !important; line-height: 22px !important; margin-bottom: 5px !important;
	text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.19); }

.sides .side-box ul { padding-left: 0px; margin-top: 30px; text-align: left;}
.sides .side-box ul li { list-style: none; border-bottom: 1px solid #ECEDF1; margin-bottom: 25px;
	padding-left: 20px;  padding-right: 20px; }
.sides .side-box ul li span {  background: url("../public/Star 8.png") no-repeat; width: 16px; height: 16px; display: inline-block; 
	vertical-align: top; position: relative; top: 4px; margin-right: 25px;}
.sides .side-box ul li p { color:#394149 !important; font-family: MontserratRegular; font-size: 14px !important; line-height: 24px !important; display: inline-block; width: 89%;
	margin-bottom: 25px !important;} 
.sides .side-box button { background: linear-gradient(93.51deg, #44CDC8 0%, #0D3696 100%); border-radius: 4px; 
	color:#FAFAFF; font-family: MontserratBold; font-size: 14px; line-height: 24px; text-transform: none;
	padding: 14px 19px; width: 100%; max-width: 364px; }

.side-box-wrapper { position: relative;}
.sides .side-box-back { background: #FFFFFF; opacity: 0.35;	
	box-shadow: 0px 7px 15px rgba(168, 168, 168, 0.12);
	border-radius: 15px; transform: rotate(-5.95deg);
	position: absolute; width: 500px; height: 100%; left: 0;top: 0;
}
.sides .side-box-back-2 { background: #FFFFFF; opacity: 0.7;	
	box-shadow: 0px 7px 15px rgba(168, 168, 168, 0.12);
	border-radius: 15px; transform: rotate(-3.66deg);
	position: absolute; width: 500px; height: 100%; left: 0;top: 0;
}

	@media (max-width: 640px) { 
		.sides { padding: 0 20px; background-position: 50% 5%; background-size: 30%; }
		.search-price {
			position: relative;
			margin: auto 0;
			right: unset;
			background: none;
		}
		.sides .side-box { width: auto; padding: 40px 20px; }
		.sides .side-box .orange-box { padding-top: 10px; padding-bottom: 10px; padding-left: 3px;padding-right: 3px;}
		.sides .side-box .orange-box .price { font-size: 40px; }
		.sides .side-box .orange-box p { font-size: 14px; }
		.sides .side-box .orange-box .price-wrapper { margin-left: auto; margin-right: auto; padding: 0 3px;}
		.sides .side-box ul li { padding-right: 10px; }
		.sides .side-box ul li p { width: 80%; }
		.sides .sides-wrapper .left-side { margin-right: 0px; padding-bottom: 0px; padding-top: 50px; text-align: center; }		
		.sides .sides-wrapper .left-side h3 {  font-size: 30px; line-height: 41px; margin-bottom: 50px;}
		.sides .side-box-back { width: 0px; }
		.sides .side-box-back-2 { width: 0px; }
	}


.statement .banner { background: url("../public/statement-banner.jpg") no-repeat; background-size : cover; min-height: 450px; background-position: center;
	padding-top: 90px; padding-bottom: 55px;}	
.statement .banner h1 { font-family: MontserratBold; font-style: normal; font-size: 48px; line-height: 59px; color: #fff;
	margin-bottom: 20px; }
.statement .banner .copy-wrapper { max-width: 925px; margin: 0 auto;}
.statement .banner .copy { text-align: center; }
.statement .banner p { font-family: MontserratRegular; font-size: 24px;	line-height: 32px; color: #FFFFFF;
	margin-bottom: 23px; }

.with-star span { background: url("../public/Star 7.png") no-repeat; width: 12px; height: 11px; 
	display: inline-block; margin-right: 5px; vertical-align: middle;}
.with-star { display: inline-block; font-family: MontserratBold; font-size: 14px; line-height: 15px; color: #fff;
	margin-right: 24px;}
.with-star:nth-last-of-type(1) { margin-right: 0px; }

.statement .banner a:link, .statement .banner a:visited {
	border-radius: 4px; max-width: 220px !important; text-align: center; margin-right: 10px;
	text-decoration: none; margin-top: 30px; } 
	.statement .banner a:link:hover { color: #FFF; }
.statement .banner a:link:nth-of-type(2) { background: transparent; border: 1px solid #FFFFFF;
	box-sizing: border-box;	border-radius: 4px;}
.statement .btn-wrapper { text-align: center;}

	@media (max-width: 640px) { 
		.statement .banner { background: url("../public/statement-banner-m.jpg") no-repeat; min-height: 450px;
			padding-top: 40px; }	
		.statement .banner .copy { width: auto;     padding: 0px 20px; }
		.statement .banner h1 { font-size: 28px; line-height: 34px; width: auto; margin-bottom: 10px;}
		.statement .banner p { font-size: 14px;}		
		.statement .banner a:link { margin-bottom: 10px;}		

		.statement .sides .side-box .orange-box .star { width: 30px; height: 39px; background-size: contain; }
	}

.statement .sides .sides-wrapper .left-side h3 { color:#03589C; font-family: MontserratBold; font-size: 22px; line-height: 27px; 
	margin-bottom: 15px;}
.statement .sides .sides-wrapper .left-side p { color:#A5AAAF; font-family: MontserratRegular; font-size: 12px; line-height: 15px; 
	margin-bottom: 30px;}
.statement .sides { background: none; background-color: #FAFAFF;	}
.statement .sides .sides-wrapper { text-align: center; }
.statement .sides .sides-wrapper .left-side label { color:#394149; font-family: MontserratBold; font-size: 10px; line-height: 12px; 
	text-transform: uppercase;}
.statement .sides .sides-wrapper .left-side input { border: 1px solid #A5AAAF; box-sizing: border-box; border-radius: 4px;
	color: #394149; font-family: MontserratRegular; font-size: 14px; line-height: 24px;
	display: block; width: 100%; margin-bottom: 10px;
 }
 .statement .sides .sides-wrapper .left-side button { background: #F37217;	border-radius: 4px; padding: 14px 19px;
	color:#FAFAFF; font-family: MontserratBold; font-size: 14px; line-height: 24px; text-transform: none; width: 220px;
  }
.statement .clocks { max-width: 45%; margin: 0 auto; padding: 70px 0;}
.statement .clocks img, .statement .clocks h4 { display: inline-block;}
.statement .clocks h4 { width: 66%; text-align: center; color: #03589C; font-size: 24px; line-height: 32px;
	vertical-align: middle; margin-left: 10%;}
	@media (max-width: 640px) { 
		.statement .clocks { max-width: 100%; margin: 0 auto; padding: 70px 20px; text-align: center;}
		.statement .clocks h4 { width: auto; margin-left: 0%; margin-top: 11%;}
	}

/* Register Trademark page*/
.reg-trademark .banner { background: url("../public/register-trademark-banner.jpg") no-repeat; background-size : cover; min-height: 507px; background-position: center;
	padding-top: 60px; padding-bottom: 55px;}	
.reg-trademark .banner h1 { font-family: MontserratBold; font-style: normal; font-size: 48px; line-height: 59px; color: #fff;
	margin-bottom: 25px; }
.reg-trademark .banner .copy-wrapper { max-width: 1200px; margin: 0 auto;}
.reg-trademark .banner .copy { text-align: left; width: 50%; margin-left: 50%; }
.reg-trademark .banner p { font-family: MontserratRegular; font-size: 17px;	line-height: 22px; color: #FFFFFF;}
.reg-trademark .banner ul {padding-left: 0px; margin-bottom: 30px;}
.reg-trademark .banner li { font-family: MontserratRegular; font-size: 16px;	line-height: 34px; color: #FFFFFF; 
	list-style: none; }
	.reg-trademark .banner li span { background: url("../public/cts-check.png") no-repeat; width: 20px; height: 20px; 
		display: inline-block; margin-right: 20px; vertical-align: middle;}
	.reg-trademark .banner li p { display: inline-block;}
.reg-trademark .banner a:link, .reg-trademark .banner .banner a:visited {
	border-radius: 4px; max-width: 290px !important; text-align: center; margin-right: 10px;
	text-decoration: none; } 
	.reg-trademark .banner a:hover { color: #FFF !important; }
.reg-trademark .banner a:link:nth-of-type(2) { background: transparent; border: 1px solid #FFFFFF;
	box-sizing: border-box;	border-radius: 4px; max-width: 220px !important;}

.reg-trademark .banner .orange-btn:nth-of-type(1) { max-width: 320px !important;}

.reg-trademark .banner .orange-btn:nth-of-type(2) { background: transparent; border: 1px solid #FFFFFF;
	box-sizing: border-box;	border-radius: 4px; max-width: 220px !important;text-align: center;}
	@media (max-width: 1100px) {
		.reg-trademark .banner .copy { text-align: left; width: 70%; margin-left: 30%; }
	}
	@media (max-width: 920px) {
		.reg-trademark .banner .copy { text-align: left; width: 80%; margin-left: 20%; }
	}
	@media (max-width: 700px) {
		.reg-trademark .banner { background: url("../public/register-trademark-banner-m.jpg") no-repeat; min-height: 600px;
			padding-top: 40px; background-size: cover;}	
		.reg-trademark .banner .copy { width: auto;     padding: 0px 20px; margin: 0%; }
		.reg-trademark .banner h1 { font-size: 28px; line-height: 34px; width: auto; margin-bottom: 10px;}
		.reg-trademark .banner p { font-size: 14px; width: auto;}
		.reg-trademark .banner li { font-size: 14px; line-height: 30px;  }
		.reg-trademark .banner li span { vertical-align: top;	margin-top: 5px;}
		.reg-trademark .banner a:link { margin-bottom: 10px;}
		.reg-trademark .banner a:link:nth-of-type(2) { max-width: 290px !important; }
		.reg-trademark .banner .orange-btn:nth-of-type(2) {width: 100%;max-width: 320px !important;}
		.reg-trademark .banner ul {margin-bottom: 0px; }
	}

.fast-easy.how-it-works .fast-box { max-width: 230px; }
.fast-easy.how-it-works .fast-box:nth-last-child(1) p.orange { margin-top: 35px;  margin-bottom: 35px; }

.reg-trademark .sides { background: url("../public/blonde-woman.png") no-repeat 80% 100% #ECEDF1;}
.reg-trademark .sides .side-box { padding-top: 80px; }
.reg-trademark .sides .sides-wrapper .right-side { display: inline-block; vertical-align: top; max-width: 335px; margin-right: 0px;
	padding-top: 103px; padding-bottom: 0px; margin-left: 2%;}
.reg-trademark .sides .sides-wrapper .left-side { display: inline-block; padding-top: 0px; padding-bottom: 0px; }
.reg-trademark .sides .sides-wrapper .left-side p { color:#A5AAAF; font-family: MontserratRegular; font-size: 12px; line-height: 15px; 
	margin-bottom: 30px;}
.reg-trademark .sides .sides-wrapper .right-side h3 { color:#7B848D; font-family: MontserratBold; font-size: 36px; line-height: 44px; 
	margin-bottom: 15px;}
.reg-trademark .sides .sides-wrapper .right-side .right-side-body { color:#7B848D; font-family: MontserratRegular; font-size: 18px; line-height: 32px; 
	margin-bottom: 50px;}
.reg-trademark .faq { padding-top: 100px; }
.mb-3 {
	margin-left: 0px;
}
@media (max-width: 640px) {
	.reg-trademark .sides .sides-wrapper .right-side h3 { font-size: 28px; line-height: 34px; }
	/* mobile version */
	.reg-trademark .sides .sides-wrapper .right-side { margin-left: 0%;     padding-top: 50px; }
	.reg-trademark .sides .sides-wrapper .left-side { padding-top: 75%; }
	.reg-trademark .sides {
		background: url("../public/blonde-woman.png") no-repeat 90% 39% #ECEDF1;
		background-size: 80%;
	}
	.reg-trademark .sides .sides-wrapper img.lockup { width: 100%; }
}

.faq-category .h4 { font-family: MontserratRegular; font-style: normal; font-size: 18px; line-height: 22px;
		   color: #f37217;  font-weight: 400; }

/* Trademark Monitoring page*/
.monitoring .banner { background: url("../public/monitoring-banner.jpg") no-repeat; background-size : cover; min-height: 507px; background-position: center;
	padding-top: 160px; padding-bottom: 55px;}	
.monitoring .banner h1 { font-family: MontserratBold; font-style: normal; font-size: 48px; line-height: 59px; color: #fff;
	margin-bottom: 25px; }
.monitoring.banner .copy-wrapper { max-width: 1200px; margin: 0 auto;}
.monitoring .banner .copy { text-align: center; }
.monitoring .banner p { font-family: MontserratRegular; font-size: 24px; line-height: 32px; color: #FFFFFF; 
	width: 40%; margin: 0 auto;}	
.monitoring .banner a:link, .statement .banner a:visited {
	border-radius: 4px; max-width: 220px !important; text-align: center; 
	text-decoration: none; margin-top: 30px; } 
.monitoring .banner a:link:hover { color: #FFF; }

	@media (max-width: 640px) { 
		.monitoring .banner { background: url("../public/monitoring-banner-m.jpg") no-repeat; min-height: 507px; padding-top: 65px;		 }	
		.monitoring .banner .copy { width: auto;     padding: 0px 20px; margin: 0%; }
		.monitoring .banner h1 { font-size: 28px; line-height: 34px; width: auto; margin-bottom: 10px;}
		.monitoring .banner p { font-size: 22px; line-height: 27px; width: auto;}		
	}
.monitoring	.fast-easy { border-bottom: 0px; background: #FFF; }

.monitoring	.sides { background: none; background-color: #FAFAFF; margin-top: 60px	}
.monitoring .sides .sides-wrapper .left-side { padding-top: 0px; padding-bottom: 0px;  }
.monitoring .sides .side-box { padding-top: 65px; margin-top: -50px; }
.monitoring .sides .sides-wrapper .right-side { width: 50%; padding-top: 50px;}
.monitor-box { margin-bottom: 40px;}
.monitor-box:nth-last-of-type(1) { margin-bottom: 0px;}
.monitor-box img { display: inline-block; }
.monitor-box p { font-family: MontserratBold; font-size: 18px; line-height: 22px; color: #03589C;  
	display: inline-block; width: 61%; vertical-align: middle; margin-left: 20px;}

	@media (max-width: 640px) { 
		.monitoring .sides .sides-wrapper .right-side { width: auto; text-align: center; }
		.monitor-box p { margin-left: 0px; width: 85%; margin-top: 20px;}
		.monitoring .sides .sides-wrapper .left-side { padding-top: 120px; padding-bottom: 50px;}
	}

/* Office Action Response */
.action .banner { background: url("../public/action-banner.jpg") no-repeat; background-size : cover; min-height: 507px; background-position: center;
	padding-top: 160px; padding-bottom: 55px;}	
.action .banner h1 { font-family: MontserratBold; font-style: normal; font-size: 48px; line-height: 59px; color: #fff;
	margin-bottom: 25px; }
.action.banner .copy-wrapper { max-width: 1200px; margin: 0 auto;}
.action .banner .copy { text-align: center; }
.action .banner p { font-family: MontserratRegular; font-size: 24px; line-height: 32px; color: #FFFFFF; 
	width: 44%; margin: 0 auto;}
.action .banner a:link, .action .banner a:visited {
		border-radius: 4px; max-width: 220px !important; text-align: center; margin-right: 10px;
		text-decoration: none; margin-top: 30px; } 	

	@media (max-width: 640px) { 
		.action .banner { background: url("../public/action-banner-m.jpg") no-repeat; min-height: 507px; padding-top: 41px; }	
		.action .banner .copy { width: auto;     padding: 0px 20px; margin: 0%; }
		.action .banner h1 { font-size: 28px; line-height: 34px; width: auto; margin-bottom: 10px;}
		.action .banner p { font-size: 22px; line-height: 27px; width: auto;}		
	}

.action .fast-easy { background: #FFF; border-bottom: 0px }
.action .fast-easy h2, .action .fast-easy h3 { display: none;} 
.action	.sides .side-box { padding-top: 70px; margin-top: -50px; }
.action	.sides { margin-top: 50px; background: none; background: #FAFAFF; } 
.action .sides .sides-wrapper .left-side { text-align: center; padding-bottom: 45px;} 
.action .sides .sides-wrapper .left-side h3 { font-family: MontserratBold; font-style: normal; font-size: 22px; line-height: 27px; color: #394149;}
.action .sides .sides-wrapper .left-side p { font-family: MontserratRegular; font-style: normal; font-size: 18px; line-height: 22px; color: #03589C; }
.action .sides .sides-wrapper .left-side p.small { font-family: MontserratRegular; font-style: normal; font-size: 12px; line-height: 15px; color: #7B848D; 
	margin-bottom: 30px; margin-top: 10px; }

.action .sides .sides-wrapper .left-side button { background: #F37217;	border-radius: 4px;	padding: 14px 19px;
	color: #FAFAFF; font-family: MontserratBold; font-size: 14px; line-height: 24px; text-transform: none;	width: 220px;
	margin-right: 10px;
}
.action .sides .sides-wrapper .left-side button:nth-last-of-type(1) { margin-right: 0px; }
@media (max-width: 640px) { 
	.action .sides .sides-wrapper .left-side button { width: 134px;}
	.action .sides .sides-wrapper .left-side { padding-bottom: 115px; }
}

/* Filling a Renewal page */
.alive .banner { background: url("../public/alive-banner.jpg") no-repeat; background-size : cover; min-height: 507px; background-position: center;
	padding-top: 130px; padding-bottom: 0px;}	
.alive .banner h1 { font-family: MontserratBold; font-style: normal; font-size: 48px; line-height: 59px; color: #fff;
	margin-bottom: 20px; }
.alive.banner .copy-wrapper { max-width: 1200px; margin: 0 auto;}
.alive .banner .copy { text-align: center; }
.alive .banner p { font-family: MontserratRegular; font-size: 24px; line-height: 32px; color: #FFFFFF; 
	width: 44%; margin: 0 auto;}
.alive .banner a:link, .alive .banner a:visited {
		border-radius: 4px; max-width: 260px !important; text-align: center; margin-right: 10px;
		text-decoration: none; margin-top: 30px; } 	

.alive .with-star { margin-top: 95px; }

	@media (max-width: 640px) { 
		.alive .banner { background: url("../public/alive-banner-m.jpg") no-repeat; min-height: 507px; padding-top: 34px; 
			background-size: cover; padding-bottom: 20px;}	
		.alive .banner .copy { width: auto;     padding: 0px 20px; margin: 0%; }
		.alive .banner h1 { font-size: 28px; line-height: 34px; width: auto; margin-bottom: 10px;}
		.alive .banner p { font-size: 22px; line-height: 27px; width: auto;}		
		.alive .with-star { margin-top: 8px; margin-right: 0px; font-size: 12px; }	
		.alive .btn-wrapper	{ margin-bottom: 50px; }
	}

.alive .fast-easy { background: #FFF; border-bottom: 0px }
.alive .fast-easy h2, .alive .fast-easy h3 { display: none;} 

.alive .sides { background: none; background-color: #FAFAFF; margin-bottom: 110px;}
.alive .sides .side-box { padding-top: 75px; margin-top: 20px; margin-bottom: -60px; }
.alive .sides h2 { font-family: MontserratBold; font-style: normal; font-size: 22px; line-height: 27px; color: #394149; 
	text-align: center; padding-top: 70px; }
.alive .sides .sides-wrapper .left-side p { color: #03589C; font-size: 14px; line-height: 24px; margin-bottom: 0px;}
.alive .sides .sides-wrapper .left-side { padding-top: 80px;  }
@media (max-width: 640px) { 
	.alive .sides h2 { margin-bottom: 0px;}
	.alive .sides .side-box { margin-top: 75px; }
}

/* Timeline */
.timeline { padding: 40px 0; text-align: center;}
.timeline .wrapper { margin-top: 55px;}
.timeline h2 { color: #394149; font-family: MontserratBold; font-size: 22px; line-height: 27px; margin-bottom: 15px; }
.timeline .t-box { display: inline-block; max-width: 290px; vertical-align: top; margin-right: 9%; }
.timeline .t-box:nth-last-child(1) {margin-right: 0%;}
.timeline .t-box p {color: #7B848D; font-family: MontserratRegular; font-size: 14px; line-height: 24px;
	margin-top: 10px;}
.timeline .t-box p.orange { color:#F37217; font-family: MontserratBold; font-size: 16px; line-height: 20px; 
	margin-top: 25px;  margin-bottom: 0px;}

	@media (max-width: 640px) { 
		.timeline { padding: 20px; }		
		.timeline .wrapper { margin-top: 40px; }
		.timeline .t-box { margin-right: 0%; margin-bottom: 10%; }
	}


/* Trust Pilot Review box */
.tp-review { max-width: 1071px; width: auto; margin: 0 auto; background: #FFFFFF; border: 1px solid #ECEDF1;
	padding: 20px 0px;  margin-top: 40px; box-shadow: 0px 7px 15px rgba(168, 168, 168, 0.12); margin-bottom: 40px; 
	padding-right: 20px;}
.tp-review .review-left { display: inline-block; vertical-align: top; text-align: center; width: 25%; margin-top: 8%; }
.tp-review .review-right { display: inline-block; width: 75%; text-align: center;  }

.tp-review .review-left .tpr-box { text-align: center;}
.tp-review .review-left .tpr-box h3 { font-family: 'PoppinsBold'; font-size: 20px; line-height: 18px; color: #161618; font-weight: 600;}
.tp-review .review-left .tpr-box .star-box { width: 22px; height: 22px; background: url("../public/trustpilot-start.png") no-repeat center;
	display: inline-block;  vertical-align: top; margin-right: 2px; }
	.tp-review .review-left .tpr-box .stars-wrapper { display: inline-block;  height: 22px; vertical-align: middle; margin-bottom: 6px; }
	.tp-review .review-left .tpr-box p { color: #000000; font-family: 'PoppinsRegular'; font-size: 10px; line-height: 15px; margin-bottom: 0px;}

.tp-review .tpr-box { max-width: 140px;  background-color: #fff; display: inline-block; text-align: left; 
	vertical-align: top; margin-right: 0%;   }
.tp-review .tpr-box:nth-last-child(1) { margin-right: 0px;} 
.tp-review .tpr-box .star-box { width: 14px; height: 14px; background: url("../public/rate-star.png") no-repeat center;
  display: inline-block;  vertical-align: top; margin-right: 2px; background-size: 85%;}
.tp-review .tpr-box .star-box:nth-last-child(1) { margin-right: 0px;}
.tp-review .tpr-box .stars-wrapper { display: inline-block;  height: 14px; vertical-align: middle;}
.tp-review .tpr-box .star-box.rate5 { background-color: #50B27F;}
.tp-review .tpr-box .star-box.rate4 { background-color: #89CB41;}
.tp-review .tpr-box .star-box.rate3 { background-color: #F8CE46;}
.tp-review .tpr-box .star-box.rate2 { background-color: #F08B3E;}
.tp-review .tpr-box .star-box.rate1 { background-color: #EC4A34;}
.tp-review .tpr-box .star-box.rate0 { background-color: #DCDCE5;}
.tp-review .tpr-box h4 { font-family: 'PoppinsLight'; font-size: 12px; line-height: 18px; color: #161618; font-weight: 600;
	margin-top: 3px;}
.tp-review .tpr-box p { font-family: 'PoppinsRegular'; font-size: 11px; line-height: 16px; color: #56555A;}
.tp-review .tpr-box p.autor { font-family: 'PoppinsRegular'; font-size: 11px; line-height: 16px; color: #7C7A8A; margin-bottom: 5px;}

.tp-review .review-left .logo { width: 78px; height: 18px; background: url("../public/trust-pilot-logo.png") no-repeat center;  }

.tp-review .react-multiple-carousel__arrow { background: rgba(0,0,0,0.1); min-width: 23px;  min-height: 5px; }
.tp-review .react-multiple-carousel__arrow::before { font-size: 14px; }
.tp-review .react-multiple-carousel__arrow--left { left: -webkit-calc(4% + 1px);  left: calc(1%); }
.tp-review .react-multiple-carousel__arrow--right { right: -webkit-calc(4% + 1px); right: calc(0%); }
.tp-review .react-multi-carousel-list { padding: 0 10px; }

@media (min-width: 769px) and (max-width: 1024px) { /* iPad Pro*/	
	.tp-review { max-width: 84%; }
}
@media (min-width: 640px) and (max-width: 768px) { /* iPad */	
	.tp-review { max-width: 96%; }
}
@media (max-width: 640px) {  
	.tp-review { max-width: 90%; padding-right: 5px; margin-bottom: 0px;}
	.tp-review .review-right { width: 52%; }
	.tp-review .review-left { width: 48%; margin-top: 24%; }
	.tp-review .review-right .tpr-box { width: 140px; }
	.tp-review .tpr-box p.autor { margin-bottom: 0px; }
	
}
@keyframes async-select-focus {
  from {
		opacity: 0;
	}
  to {
		opacity: 1;
	}
}

.async-select-container {
	position: relative;
}

.async-select-container .async-select__control,
.async-select-container .async-select-valid__control,
.async-select-container .async-select-invalid__control {
  min-height: unset !important;
	height: auto;
  padding: 0;
  font-size: 14px;
}

.async-select-container .async-select__control.async-select__control--is-focused::before,
.async-select-container .async-select-valid__control.async-select-valid__control--is-focused::before,
.async-select-container .async-select-invalid__control.async-select-invalid__control--is-focused::before {
	content: "";
	display: block;
	position: absolute;
	top: -5px;
	bottom: -5px;
	left: -5px;
	right: -5px;
	background-color: #F37217;
	border-radius: 8px;
	animation-name: async-select-focus;
	animation-timing-function: ease-in-out;
  animation-duration: .15s;
}

.async-select-container .async-select__control .async-select__indicator,
.async-select-container .async-select-valid__control .async-select-valid__indicator,
.async-select-container .async-select-invalid__control .async-select-invalid__indicator {
  padding: 0;
	position: absolute;
	right: 12px;
	z-index: 2;
}

.async-select-container .async-select-valid__control .async-select-valid__indicator,
.async-select-container .async-select-invalid__control .async-select-invalid__indicator {
	right: 8px;
	height: calc(0.75rem + 0.375rem);
	width: calc(0.75rem + 0.375rem);
}

.async-select-container .async-select__control .async-select__indicator.async-select__clear-indicator {
	margin-right: 12px;
}

.async-select-container .async-select-valid__control .async-select-valid__indicator.async-select-valid__clear-indicator,
.async-select-container .async-select-invalid__control .async-select-invalid__indicator.async-select-invalid__clear-indicator {
	margin-right: 18px;
}

.async-select-container .async-select__control .async-select__indicator.async-select__loading-indicator {
	margin-right: 16px;
}

.async-select-container .async-select-valid__control .async-select-valid__indicator.async-select-valid__loading-indicator,
.async-select-container .async-select-invalid__control .async-select-invalid__indicator.async-select-invalid__loading-indicator {
	margin-top: 16px;
	margin-right: 24px;
}

.async-select-container .async-select__control .async-select__indicator.async-select__clear-indicator,
.async-select-container .async-select-valid__control .async-select-valid__indicator.async-select-valid__clear-indicator,
.async-select-container .async-select-invalid__control .async-select-invalid__indicator.async-select-invalid__clear-indicator {
  cursor: pointer;
}

.async-select-container .async-select__control .async-select__indicator-separator,
.async-select-container .async-select-valid__control .async-select-valid__indicator-separator,
.async-select-container .async-select-invalid__control .async-select-invalid__indicator-separator {
  display: none;
}

.async-select-container .async-select__control .async-select__value-container,
.async-select-container .async-select-valid__control .async-select-valid__value-container,
.async-select-container .async-select-invalid__control .async-select-invalid__value-container {
	height: 48px;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-right: 48px;
	padding-left: 12px;
  border-radius: 4px;
	border: 1px solid #A5AAAF;
	background-color: #FFFFFF;
	box-shadow: 0 0 0rem 3px #FFFFFF;
	z-index: 1;
}

.async-select-container .async-select-valid__control .async-select-valid__value-container {
	border: 1px solid #198754;
}

.async-select-container .async-select-invalid__control .async-select-invalid__value-container {
	border: 1px solid #dc3545;
}

.async-select-container .async-select__control.async-select__control--is-focused .async-select__value-container,
.async-select-container .async-select-valid__control.async-select-valid__control--is-focused .async-select-valid__value-container,
.async-select-container .async-select-invalid__control.async-select-invalid__control--is-focused .async-select-invalid__value-container {
	border-color: #86b7fe;
}

.async-select-container .async-select__control .async-select__value-container .async-select__input-container,
.async-select-container .async-select-valid__control .async-select-valid__value-container .async-select-valid__input-container,
.async-select-container .async-select-invalid__control .async-select-invalid__value-container .async-select-invalid__input-container {
  padding: 0;
  margin: 0;
}


.upsell-page {
	display: flex;
	justify-content: center;
	padding-top: 60px;
	padding-bottom: 100px;
	padding-left: 20px;
	padding-right: 20px;
	border-top: 1px solid #ECEDF1;
	box-shadow: 0px 25px 23px -23px rgba(168, 168, 168, 0.3) inset;
}

.upsell-page .upsell-container {
	position: relative;
	width: 1180px;
	padding-top: 24px;
	background: #FFFFFF;
	border: 1px solid rgba(3, 88, 156, 0.15);
	box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.08);
}

.upsell-page .upsell-container .upsell-stepper {
	position: absolute;
	top: -1px;
	left: -1px;
	right: -1px;
	height: 24px;
	background: #F37217;
	font-family: MontserratBold;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	color: #FAFAFF;
}

.upsell-page .upsell-container .upsell-banner {
	padding-top: 66px;
	padding-bottom: 34px;
}

.upsell-page .upsell-container .upsell-banner .upsell-banner-title {
	margin-bottom: 24px;
	font-family: MontserratBold;
	font-size: 36px;
	line-height: 44px;
	text-align: center;	
	color: #03589C;
}

.upsell-page .upsell-container .upsell-banner .upsell-banner-text {
	padding-left: 10%;
	padding-right: 10%;
	font-family: MontserratRegular;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #7B848D;
}

.upsell-page .upsell-container .upsell-banner .upsell-banner-text > span > span {
	font-family: MontserratBold;
}

.upsell-page .upsell-container .upsell-faq {
	display: flex;
	padding-top: 60px;
	padding-bottom: 42px;
	background: #FAFAFF;
	border-top: 1px solid #ECEDF1;
	border-bottom: 1px solid #ECEDF1;
}

.upsell-page .upsell-container .upsell-faq .upsell-faq-image {
	padding-left: 68px;
	padding-right: 48px;
}

.upsell-page .upsell-container .upsell-faq .upsell-faq-group {
	flex-grow: 1;
	padding-right: 15%;
}

.upsell-page .upsell-container .upsell-faq .upsell-faq-group .upsell-faq-item {
	margin-top: 16px;
}

.upsell-page .upsell-container .upsell-faq .upsell-faq-group .upsell-faq-item .accordion-button {
	font-size: 14px;
	line-height: 24px;
}

.upsell-page .upsell-container .upsell-actions {
	padding-top: 74px;
	padding-bottom: 74px;
	padding-left: 10%;
	padding-right: 10%;
}

.upsell-page .upsell-container .upsell-actions .upsell-form {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 24px;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 30px;
	border: 1px solid rgba(3, 88, 156, 0.15);
	box-shadow: 0px 14px 24px rgba(0, 0, 0, 0.08);
}

.upsell-page .upsell-container .upsell-actions .upsell-form .upsell-form-title {
	margin-top: 24px;
	margin-bottom: 32px;
	font-family: MontserratBold;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #03589C;
}

.upsell-page .upsell-container .upsell-actions .upsell-form .upsell-form-body {
	width: 70%;
}

.upsell-page .upsell-container .upsell-actions .upsell-form .upsell-form-body .upsell-form-field {
	margin-bottom: 16px;
}

.upsell-page .upsell-container .upsell-actions .upsell-form .upsell-form-body .upsell-form-field .form-check {
	padding: 0;
}

.upsell-page .upsell-container .upsell-actions .upsell-form .upsell-form-body .upsell-form-field .form-check-input + label {
	font-family: MontserratRegular;
	font-size: 14px;
	line-height: 24px;	
	color: #394149;
}

.upsell-page .upsell-container .upsell-actions .upsell-form .upsell-form-body .upsell-form-field .form-check .form-check-input:checked + label {
	font-weight: 400;
}

.upsell-page .upsell-container .upsell-actions .upsell-form .upsell-form-body .upsell-form-field .form-check-label .additional-filing-yes > span:nth-child(2) {
	font-family: MontserratBold;
	color: #03589C;
}

.upsell-page .upsell-container .upsell-actions .upsell-form .upsell-form-body .upsell-form-field .form-check-label .additional-filing-yes > span:nth-child(3) {
	font-family: MontserratBold;
	color: #F37217;
}

.upsell-page .upsell-container .upsell-actions .upsell-form .upsell-form-body .upsell-form-field .form-check-label .additional-filing-no > span:nth-child(3) {
	font-family: MontserratRegular;
	font-size: 12px;
	line-height: 15px;
	color: #7B848D;
}

.upsell-page .upsell-container .upsell-actions .upsell-form .upsell-form-body .upsell-form-button {
	display: flex;
	justify-content: center;
	margin-top: 40px;
}

.upsell-page .upsell-container .upsell-actions .upsell-form .upsell-form-body .upsell-form-button button {
	padding: 14px 45px;
	background-color: #F37217;
	border: none;
	box-shadow: none;
	font-family: MontserratBold;
	font-size: 14px;
	line-height: 24px;
	text-transform: none;
	color: #FAFAFF;
}

.upsell-page .upsell-container .upsell-divider {
	display: none;
}

.upsell-page .upsell-container .upsell-divider + div {
	padding-top: 0;
}

.upsell-page .upsell-container .upsell-offer {
	display: flex;
	padding-top: 50px;
	padding-bottom: 50px;
	background: #FAFAFF;
	border-top: 1px solid #ECEDF1;
	border-bottom: 1px solid #ECEDF1;
}

.upsell-page .upsell-container .upsell-offer .upsell-offer-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 50%;
}

.upsell-page .upsell-container .upsell-offer .upsell-offer-item:first-child {
	border-right: 1px solid rgba(165, 170, 175, 0.25);
}

.upsell-page .upsell-container .upsell-offer .upsell-offer-item .upsell-offer-item-icon {
	margin-bottom: 10px;
}

.upsell-page .upsell-container .upsell-offer .upsell-offer-item .upsell-offer-item-text {
	width: 330px;
	font-family: MontserratBold;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	color: #7B848D;
}

.upsell-page .upsell-container .upsell-privacy-program {
	display: flex;
	flex-wrap: wrap;
	padding: 50px;
	background: #FAFAFF;
	border-top: 1px solid #ECEDF1;
	border-bottom: 1px solid #ECEDF1;
}

.upsell-page .upsell-container .upsell-privacy-program .upsell-privacy-program-title {
	width: 100%;
	margin-bottom: 40px;
	font-family: MontserratBold;
	font-size: 18px;
	line-height: 22px;
	text-align: center;
	color: #7B848D;
}

.upsell-page .upsell-container .upsell-privacy-program .upsell-privacy-program-item {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	width: 50%;
	margin-bottom: 14px;
}

.upsell-page .upsell-container .upsell-privacy-program .upsell-privacy-program-item .upsell-privacy-program-item-icon {
	display: flex;
	margin-top: 2px;
	margin-right: 8px;
}

.upsell-page .upsell-container .upsell-privacy-program .upsell-privacy-program-item .upsell-privacy-program-item-text {
	font-family: MontserratRegular;
	font-size: 16px;
	line-height: 20px;	
	color: #7B848D;
}

.upsell-spinner-container {
	display: flex;
	justify-content: center;
	align-items: center;
}
.upsell-page .upsell-container .upsell-banner ul li {
	padding-left: 10px;
	padding-right: 10px;
	line-height: 20px;
	padding-top: 12px;
}
@media (max-width: 1220px) {
	.upsell-page .upsell-container {
		width: 100%;
	}
}

@media (max-width: 768px) {
	.upsell-page .upsell-container .upsell-banner {
		padding-top: 12px;
		padding-bottom: 10px;
	}

	.upsell-page .upsell-container .upsell-banner .upsell-banner-title {
		margin-bottom: 18px;
		padding-left: 10px;
		padding-right: 10px;
		font-size: 24px;
		line-height: 32px;
	}	

	.upsell-page .upsell-container .upsell-banner .upsell-banner-text {
		padding-left: 10px;
		padding-right: 10px;
		font-size: 16px;
		line-height: 20px;
	}
	.upsell-page .upsell-container .upsell-banner ul li {
		padding-left: 10px;
		padding-right: 10px;
		font-size: 16px;
		line-height: 20px;
		padding-top: 12px;
	}
	.upsell-page .upsell-container .upsell-faq {
		padding-top: 16px;
		padding-bottom: 32px;
	}

	.upsell-page .upsell-container .upsell-faq .upsell-faq-image {
		display: none;
	}

	.upsell-page .upsell-container .upsell-faq .upsell-faq-group {
		padding-right: 0;
	}

	.upsell-page .upsell-container .upsell-actions {
		padding-top: 16px;
		padding-bottom: 24px;
		padding-left: 24px;
		padding-right: 24px;
	}

	.upsell-page .upsell-container .upsell-actions .upsell-form {
		padding: 0;
		border: none;
		box-shadow: unset;
	}

	.upsell-page .upsell-container .upsell-actions .upsell-form .upsell-form-title {
		margin-bottom: 24px;
		font-size: 16px;
		line-height: 20px;
		text-align: left;
	}

	.upsell-page .upsell-container .upsell-actions .upsell-form .upsell-form-body {
		width: 100%;
	}

	.upsell-page .upsell-container .upsell-actions .upsell-form .upsell-form-body .upsell-form-button {
		margin-top: 32px;
	}

	.upsell-page .upsell-container .upsell-divider {
		display: block;
		height: 2px;
		background: #ECEDF1;
	}

	.upsell-page .upsell-container .upsell-offer {
		flex-direction: column;
		padding-top: 2px;
		padding-bottom: 0;
	}

	.upsell-page .upsell-container .upsell-offer .upsell-offer-item {
		width: 100%;
		padding-top: 34px;
		padding-bottom: 36px;
	}

	.upsell-page .upsell-container .upsell-offer .upsell-offer-item:first-child {
		border-right: none;
		border-bottom: 1px solid rgba(165, 170, 175, 0.25);
	}

	.upsell-page .upsell-container .upsell-offer .upsell-offer-item .upsell-offer-item-icon img {
		height: 32px;
		width: 32px;
	}

	.upsell-page .upsell-container .upsell-privacy-program {
		padding-left: 20px;
		padding-right: 20px;
	}

	.upsell-page .upsell-container .upsell-privacy-program .upsell-privacy-program-item {
		width: 100%;
	}
}
